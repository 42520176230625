import React from 'react'
import TestComleted from '../../assets/all done.svg';
import { useParams } from 'react-router-dom';

const TestSubmissionPage = () => {
    const { test_type } = useParams();

    const isTest = test_type === 'test' ? true : false;
    
    return (
        <>
            <div className={ isTest ? 'flex justify-center items-center h-full' : 'mt-2 w-full' }>
                <img src={ TestComleted } preview={ false } className={ isTest ? 'w-1/2' : 'w-full' } />
            </div>
        </>
    )
}

export default TestSubmissionPage