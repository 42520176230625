import React, { useEffect, useState } from "react";
import { Checkbox } from "antd";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics/index";
import { multipleChoiceCategory, multipleChoiceAction } from "../../utils/GoogleAnalytics/events/categories";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useSelector } from 'react-redux';

const CustomCheckbox = ({ option, selected, onSelect, onNext }) => {
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onNext();
    }
  };

  return (
    <label
      className="flex items-center cursor-pointer bg-white p-2 rounded-md p-4 border-2 mt-2 lg:mr-6"
      htmlFor={`checkbox-${option.id}`}
    >
      <Checkbox
        id={`checkbox-${option.id}`}
        checked={selected?.includes(option.id)}
        onChange={() => onSelect(option)}
        onKeyDown={handleKeyDown}
        tabIndex={0}
      />
      <span className="ml-2">{option.value}</span>
    </label>
  );
};

const MultiChoiceStep = ({
  question,
  onSelect,
  onNext,
  onBack,
  selectedOptionsFromParent,
  isPagination,
  isQuestionNavigation,
  isValidation,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(
    selectedOptionsFromParent || []
  );
  const clientConfig = useSelector(state => state.clientConfiguration);

  // Extract numerical digit from the question description
  const numericalDigitMatch = question?.description.match(/\d+/);
  const numericalDigit = numericalDigitMatch ? parseInt(numericalDigitMatch[0], 10) : null;

  const handleSelect = (option) => {
    let updatedOptions = [...selectedOptions];

    if (updatedOptions.includes(option.id)) {
      updatedOptions = updatedOptions.filter((id) => id !== option.id);
    } else {
      // Restrict selection if the number of selected options has reached the limit
      if (!numericalDigit || updatedOptions.length < numericalDigit) {
        updatedOptions.push(option.id);
      }
    }

    setSelectedOptions(updatedOptions);
    onSelect(updatedOptions);

    TrackGoogleAnalyticsEvent(multipleChoiceCategory, multipleChoiceAction);
  };

  const perQuestionTimer = dynamicData.default.perQuestionTimer;

  const handleClearAll = () => {
    setSelectedOptions([]);
    onSelect([]);
  };

  useEffect(() => {
    setSelectedOptions(selectedOptionsFromParent || []);
  }, [question, selectedOptionsFromParent]);

  useEffect(() => {
    if (selectedOptions.length) {
      isValidation(true);
    } else {
      isValidation(false);
    }
  }, [selectedOptions]);

  return (
    <div className={`flex flex-col justify-between ${isPagination ? "mb-4" : "mt-2 lg:h-full"}`}>
      <div className="flex flex-col lg:h-full overflow-y-auto">
        <QuestionAndDescription question={question} />
        <div className="justify-content space-between w-full">
          {question?.options?.map((option) => (
            <CustomCheckbox
              key={option.id}
              option={option}
              selected={selectedOptions}
              onSelect={handleSelect}
              onNext={onNext}
            />
          ))}
        </div>
      </div>
      {
        isPagination ?
          <></>
          :
          isQuestionNavigation ?
            <>
              <SubmitButton onNext={onNext} onClearAll={handleClearAll} />
            </>
            :
            <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
              {
                question?.question_optional === "optional" && (
                  <button className="skip-button" onClick={onNext}>
                    Skip
                  </button>
                )
              }
              {
                perQuestionTimer ?
                  <></> :
                  <button className="back-button" onClick={onBack}>
                    Back
                  </button>

              }
              <button
                className="next-button"
                onClick={selectedOptions.length ? onNext : null}
                style={{
                  backgroundColor: selectedOptions.length ? `${clientConfig.primary_color}` : `${clientConfig.primary_color}80`,
                  cursor: selectedOptions.length ? 'pointer' : 'not-allowed'
                }}
              >
                Next
              </button>
            </div>
      }
    </div>
  );
};

export default MultiChoiceStep;
