import React, { useState } from 'react';
import axios from 'axios';
import {
    BASE_URL, FILE_UPLOAD
} from '../utils/constants/constants';

const useFileUpload = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );

    const FileUpload = async ( file, fileName, fileFolder ) => {
        setIsLoading( true );

        const formData = new FormData();
        formData.append( 'file', file );

        if ( file )
            try {
                const res = await axios.post( `${ BASE_URL }/${ FILE_UPLOAD }upload-file?file_name=${ fileName }&file_folder=${ fileFolder }`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                } );
                setResponse( res.data );
                setError( null );
            } catch ( err ) {
                setError( err );
            } finally {
                setIsLoading( false );
            }
    };

    return { response, error, isLoading, FileUpload };
};

export default useFileUpload;