import React, { useState, useEffect } from "react";
import { Button, Upload, message, Radio, notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import useFileUpload from "../../api/FileUpload";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';

const UploadFileInputType = ( {
    question,
    selected,
    onSelect,
    onNext,
    onBack,
    isPagination,
    isQuestionNavigation,
    onClearAll,
} ) => {
    const [isOptionSelected, setIsOptionSelected] = useState( !!selected?.uploadedLink );
    const clientConfig = useSelector( ( state ) => state.clientConfiguration );

    const [errorMessage, setErrorMessage] = useState( "" );
    const [fileDetails, setFileDetails] = useState( null );

    const { client_name } = useParams();

    useEffect( () => {
        const intitalUploadedFile = selected?.uploadedLink
            ? {
                name: selected.uploadedFileName,
                status: "success",
                size: selected.uploadedFileSize,
            }
            : null
        setFileDetails( intitalUploadedFile );
    }, [selected, question] );

    const {
        response: fileLink,
        error: uploadError,
        isLoading: uploadingFile,
        FileUpload,
    } = useFileUpload();

    const handleFileChange = ( event ) => {
        const file = event.target.files[0]; // Only one file allowed
        if ( !file ) {
            return;
        }

        if ( file.size > 5 * 1024 * 1024 ) {
            // 5MB size limit
            setErrorMessage( "File size exceeds 5MB limit." );
            setFileDetails( null );
            event.target.value = ""; // Clear the file input
        } else {
            setErrorMessage( "" );
            // Remove spaces from file name
            const sanitizedFileName = file.name.replace( /\s+/g, "" );
            setFileDetails( { name: sanitizedFileName, status: "uploading", size: file.size } ); // Set initial status as 'uploading'
            FileUpload( file, sanitizedFileName, client_name );
        }
    };

    useEffect( () => {
        if ( fileLink ) {
            setFileDetails( ( prevDetails ) => ( {
                ...prevDetails,
                status: "success",
            } ) );
            onSelect( {
                uploadedLink: fileLink,
                uploadedFileName: fileDetails.name,
                uploadedFileSize: fileDetails.size,
            } );
            setIsOptionSelected( true );
            notification.success( {
                message: "File uploaded successfully.",
            } );
        } else if ( uploadError ) {
            setFileDetails( ( prevDetails ) => ( {
                ...prevDetails,
                status: "error",
            } ) );
            notification.error( {
                message: "File upload failed.",
                description: "Please try again",
            } );
        }
    }, [fileLink, uploadError] );

    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    const handleClearAll = () => {
        setFileDetails( null );
        onClearAll();
    };

    return (
        <div
            className={ `flex flex-col justify-between ${ isPagination ? "mb-4" : "mt-2 lg:h-full"
                }` }
        >
            <div className="flex flex-col lg:h-full overflow-y-auto">
                <QuestionAndDescription question={ question } />
                <div className="w-full flex justify-center font-poppins">
                    <div className="flex flex-col items-center justify-center lg:w-1/2 sm:w-full">
                        <label
                            htmlFor="dropzone-file"
                            className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-transparent hover:bg-gray-100"
                        >
                            <div className="flex flex-col items-center justify-center py-4">
                                <svg
                                    width="64px"
                                    height="64px"
                                    viewBox="0 0 1024 1024"
                                    class="icon"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#000000"
                                >
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                    <g
                                        id="SVGRepo_tracerCarrier"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    ></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <path
                                            d="M736.68 435.86a173.773 173.773 0 0 1 172.042 172.038c0.578 44.907-18.093 87.822-48.461 119.698-32.761 34.387-76.991 51.744-123.581 52.343-68.202 0.876-68.284 106.718 0 105.841 152.654-1.964 275.918-125.229 277.883-277.883 1.964-152.664-128.188-275.956-277.883-277.879-68.284-0.878-68.202 104.965 0 105.842zM285.262 779.307A173.773 173.773 0 0 1 113.22 607.266c-0.577-44.909 18.09-87.823 48.461-119.705 32.759-34.386 76.988-51.737 123.58-52.337 68.2-0.877 68.284-106.721 0-105.842C132.605 331.344 9.341 454.607 7.379 607.266 5.417 759.929 135.565 883.225 285.262 885.148c68.284 0.876 68.2-104.965 0-105.841z"
                                            fill="#1640C1"
                                        ></path>
                                        <path
                                            d="M339.68 384.204a173.762 173.762 0 0 1 172.037-172.038c44.908-0.577 87.822 18.092 119.698 48.462 34.388 32.759 51.743 76.985 52.343 123.576 0.877 68.199 106.72 68.284 105.843 0-1.964-152.653-125.231-275.917-277.884-277.879-152.664-1.962-275.954 128.182-277.878 277.879-0.88 68.284 104.964 68.199 105.841 0z"
                                            fill="#1640C1"
                                        ></path>
                                        <path
                                            d="M545.039 473.078c16.542 16.542 16.542 43.356 0 59.896l-122.89 122.895c-16.542 16.538-43.357 16.538-59.896 0-16.542-16.546-16.542-43.362 0-59.899l122.892-122.892c16.537-16.542 43.355-16.542 59.894 0z"
                                            fill="#1640C1"
                                        ></path>
                                        <path
                                            d="M485.17 473.078c16.537-16.539 43.354-16.539 59.892 0l122.896 122.896c16.538 16.533 16.538 43.354 0 59.896-16.541 16.538-43.361 16.538-59.898 0L485.17 532.979c-16.547-16.543-16.547-43.359 0-59.901z"
                                            fill="#1640C1"
                                        ></path>
                                        <path
                                            d="M514.045 634.097c23.972 0 43.402 19.433 43.402 43.399v178.086c0 23.968-19.432 43.398-43.402 43.398-23.964 0-43.396-19.432-43.396-43.398V677.496c0.001-23.968 19.433-43.399 43.396-43.399z"
                                            fill="#1640C1"
                                        ></path>
                                    </g>
                                </svg>
                                <p className="mb-2 text-base font-medium text-gray-500">
                                    Drag your file(s) to start uploading
                                </p>
                                <div className="flex items-center justify-center w-full">
                                    <span className="w-1/3 h-px bg-gray-500 border-0 " />
                                    <span className="px-3 text-gray-500 font-medium">OR</span>
                                    <span className="w-1/3 h-px bg-gray-500 border-0 " />
                                </div>
                                <div className="py-2">
                                    <input
                                        type="file"
                                        id="uploadFile1"
                                        className="hidden"
                                        accept=".jpg,.png,.mp3,.mp4"
                                        onChange={ handleFileChange }
                                    />
                                    <label
                                        htmlFor="uploadFile1"
                                        className="block px-3 py-2 rounded text-blue-600 text-base tracking-wider font-medium cursor-pointer border-2 border-blue-600"
                                    >
                                        Browse Files
                                    </label>
                                </div>
                            </div>
                            <input
                                id="dropzone-file"
                                type="file"
                                className="hidden"
                                accept=".jpg,.png,.mp3,.mp4"
                                onChange={ handleFileChange }
                            />
                        </label>
                        <p className="text-sm font-light mt-2">
                            Only support jpg, png, mp3 and mp4 files. Max file size - 5MB
                        </p>
                        { errorMessage && (
                            <div className="text-red-500 text-sm mt-2">{ errorMessage }</div>
                        ) }

                        { fileDetails && (
                            <div className="flex items-center mt-4 p-2 border border-gray-300 rounded-md w-full">
                                <svg
                                    width="64px"
                                    height="64px"
                                    viewBox="-6.48 -6.48 36.96 36.96"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    stroke="#555555"
                                >
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                    <g
                                        id="SVGRepo_tracerCarrier"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    ></g>
                                    <g id="SVGRepo_iconCarrier">
                                        { " " }
                                        <path
                                            d="M21 11V15.8C21 16.9201 21 17.4802 20.782 17.908C20.5903 18.2843 20.2843 18.5903 19.908 18.782C19.4802 19 18.9201 19 17.8 19H6.2C5.0799 19 4.51984 19 4.09202 18.782C3.71569 18.5903 3.40973 18.2843 3.21799 17.908C3 17.4802 3 16.9201 3 15.8V8.2C3 7.0799 3 6.51984 3.21799 6.09202C3.40973 5.71569 3.71569 5.40973 4.09202 5.21799C4.51984 5 5.0799 5 6.2 5H15M21 11L15 5M21 11H16.6C16.0399 11 15.7599 11 15.546 10.891C15.3578 10.7951 15.2049 10.6422 15.109 10.454C15 10.2401 15 9.96005 15 9.4V5"
                                            stroke="#555555"
                                            stroke-width="1.416"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>{ " " }
                                    </g>
                                </svg>
                                <span className="flex-1">{ fileDetails.name }</span>
                                { fileDetails.status === "uploading" && (
                                    <svg
                                        className="animate-spin h-6 w-6 text-blue-500"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8v8z"
                                        ></path>
                                    </svg>
                                ) }
                                { fileDetails.status === "success" && (
                                    <svg
                                        className="h-6 w-6 text-green-500"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M5 13l4 4L19 7"
                                        />
                                    </svg>
                                ) }
                                { fileDetails.status === "error" && (
                                    <svg
                                        className="h-6 w-6 text-red-500"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                ) }
                            </div>
                        ) }
                    </div>
                </div>
            </div>
            { isPagination ? (
                <></>
            ) : isQuestionNavigation ? (
                <>
                        <SubmitButton onNext={ onNext } onClearAll={ handleClearAll } />
                    </>
                ) : (
                    <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                        { question?.question_optional === "optional" && (
                            <button className="skip-button" onClick={ onNext }>
                                Skip
                            </button>
                        ) }
                        { perQuestionTimer ? (
                            <></>
                        ) : (
                            <button className="back-button" onClick={ onBack }>
                                Back
                            </button>
                        ) }
                        <button
                            className="next-button"
                            onClick={ isOptionSelected ? onNext : null }
                            style={ {
                                backgroundColor: isOptionSelected
                                    ? clientConfig.primary_color
                                    : `${ clientConfig.primary_color }80`,
                                cursor: isOptionSelected ? "pointer" : "not-allowed",
                            } }
                        >
                            Next
                        </button>
                    </div>
            ) }
        </div>
    );
};

export default UploadFileInputType;
