import React from "react";
import { useSelector } from "react-redux";
import { ClockCircleFilled } from "@ant-design/icons";

const StillTimer = ( { testTimeInSeconds, isWide } ) => {
    const clientConfig = useSelector( ( state ) => state.clientConfiguration );

    const mainTimer = () => {
        const minutes = Math.floor( testTimeInSeconds / 60 );
        const seconds = Math.floor( testTimeInSeconds % 60 );
        return `${ minutes }:${ seconds }`;
    };

    return (
        <p
            className={ `${ isWide ? "text-2xl" : "text-lg"
                } text-black font-semibold w-full` }
        >
            Time Left :{ " " }
            <span className={ `${ isWide ? "text-2xl text-green-600" : "text-green-600 text-lg" }` }>
                { mainTimer() }
            </span>
        </p>
    );
};

export default StillTimer;
