import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { dynamicData } from '../../helpers/data';
import { notification, Spin } from 'antd';
import useGeneratePdfReport from '../../api/useGeneratePdfReport';
import { useSelector } from 'react-redux';
import ThankYouPageTwo from './ThankYouPageTwo';

export const ThankYouIVB = () => {

    const location = useLocation();
    const [studentRollNumber, setStudentRollNumber] = useState( null );


    const params = new URLSearchParams( location.search );
    const template_id = params.get( 'template_id' );
    
    const student_name = localStorage.getItem( 'studentName' ) || "abc";
    const student_email = localStorage.getItem( 'user_email' ) || "abc@gmail.com";

    const formConfigRedux = useSelector( state => state.formConfiguration );
    const formConfig = formConfigRedux?.form_config;
    const formid = formConfig?.form_id;

    const { data: pdfReport, isLoading: pdfReportGenerating, generatePdfReport } = useGeneratePdfReport();

    const bookingLink = formConfig?.booking_url;

    const handleBooking = () => {
        if ( bookingLink ) {
            window.open( `${ bookingLink }`, '_blank' );
        }
    }

    useEffect( () => {
        if ( studentRollNumber ) {
            generatePdfReport( studentRollNumber, formid )
        }
    }, [studentRollNumber] )

    useEffect( () => {
        const student_roll_number = localStorage.getItem( 'student_roll_number' ) || localStorage.getItem( 'user_id' )
        setStudentRollNumber( student_roll_number )
    }, [localStorage.getItem( 'student_roll_number' )] );

    return (
        <>
            <ThankYouPageTwo
                handlementorshipbooking={ handleBooking }
            />
        </>
    );
};

