import React, { useState, Fragment } from 'react';
import Lock from '../../assets/lock.svg';
import { LockFilled } from '@ant-design/icons';

const TestTermsAndConditions = ({ onSubmit, link }) => {
  const [agree, setAgree] = useState(false);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-60"></div>

      {/* Modal box */}
      <div className="relative flex flex-col p-4 md:p-8 bg-white rounded-lg max-h-[90%] overflow-auto z-10 shadow-lg">
        <img
          src={Lock}
          alt='Lock'
          className="w-16 md:w-[20rem] sm:w-[15rem] mx-auto mb-4 md:mb-6"
        />
        <Fragment>
          <label
            className="flex items-center border rounded p-2 mb-2 cursor-pointer"
            onClick={() => setAgree(true)}
          >
            <input
              type='radio'
              value='agree'
              className="w-3 h-3 mr-2 md:mr-4"
              checked={agree}
              onChange={() => setAgree(!agree)}
            />
            <span className="text-sm md:text-base">
              I consent to the{' '}
              <a
                href={link}
                rel="noreferrer"
                className="text-blue-700"
              >
                terms and conditions
              </a>
            </span>
          </label>
          <button
            className='px-2 py-1 md:px-4 md:py-2 rounded-md border-2 text-white bg-blue-500 text-sm md:text-base'
            onClick={() => onSubmit()}
            disabled={!agree}
          >
            Proceed
          </button>
        </Fragment>
        <div className="flex items-center mt-4 md:mt-6 text-gray-400 text-sm md:text-lg text-center justify-center">
          <LockFilled style={{ fontSize: '16px', md: '30px' }} />
          Compliant with the GDPR
        </div>
      </div>
    </div>
  );
};

export default TestTermsAndConditions;
