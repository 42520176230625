import React, { useState, useEffect } from 'react';
import ThankYou from '../../assets/thank_you.png'
import EnableTick from '../../assets/enable_tick.svg';
import DisableTick from '../../assets/disable_tick.svg';
import LoaderAnimation from '../../assets/Loader Animation.gif';
import { useSelector, useDispatch } from 'react-redux';
import { dynamicData } from "../../helpers/data";
import { useParams, Link } from 'react-router-dom';
import { setIsFormSubmitted } from '../../redux/student/studentSlice';
import useGeneratePdfReport from '../../api/useGeneratePdfReport';

const Review = ( { data } ) => {
  const [savedEmail, setSavedEmail] = useState( 'default@email.com' );
  const [productCode, setProductCode] = useState( 'DEMO12' );
  const [phoneNumber, setPhoneNumber] = useState( '1234567890' );
  const [userName, setUserName] = useState( 'UserName' );
  const [userAssessmentResponseID, setUserAssessmentResponseID] = useState( null );
  const { client_name } = useParams();
  const dispatch = useDispatch();

  const isStudent = useSelector( state => state.student.isStudent )
  const isParent = useSelector( state => state.parent.isParent )
  const useMountEffect = ( fun ) => useEffect( fun, [] )

  const studentRollNumber = localStorage.getItem( 'student_roll_number' )

  const numberOfQuestions = data?.questions?.length || 0;
  let heading = dynamicData.ttm.loading_page.heading;
  let pointers = dynamicData.ttm.loading_page.pointers;

  if ( isStudent ) {
    heading = dynamicData.default?.student_content?.loading_page?.heading || heading;
    pointers = dynamicData.default?.student_content?.loading_page?.pointers || pointers;
  } else if ( isParent ) {
    heading = dynamicData.default?.parent_content?.loading_page?.heading || heading;
    pointers = dynamicData.default?.parent_content?.loading_page?.pointers || pointers;
  } else {
    heading = dynamicData.default?.loading_page?.heading || heading;
    pointers = dynamicData.default?.loading_page?.pointers || pointers;
  }

  const [showReportButton, setShowReportButton] = useState( false );

  const initialEnableIcons = Array( pointers.length ).fill( false );

  const [enableIcons, setEnableIcons] = useState( initialEnableIcons );

  const { generatePdfReport } = useGeneratePdfReport();

  useEffect( () => {
    if ( userAssessmentResponseID ) {
      generatePdfReport( studentRollNumber )
    }
  }, [userAssessmentResponseID] )

  useEffect( () => {
    const user_email = localStorage.getItem( 'user_email' );
    const product_code = localStorage.getItem( 'product_code' );
    const phone_number = localStorage.getItem( 'phone_number' );
    const userAssessmentResponseID = localStorage.getItem( 'userAssessmentResponseID' );
    const user_name = localStorage.getItem( 'user_name' ) || localStorage.getItem( 'studentName' );

    setUserName( user_name );
    setSavedEmail( user_email );
    setProductCode( product_code );
    setPhoneNumber( phone_number );
    setUserAssessmentResponseID( userAssessmentResponseID )
  }, [] );

  useEffect( () => {
    // Create timers based on the length of pointers
    const timers = pointers.map( ( _, index ) => {
      return setTimeout( () => {
        setEnableIcons( prevState => {
          const newState = [...prevState];
          newState[index] = true;
          return newState;
        } );
      }, ( index + 1 ) * 3000 ); // Increase the time for each pointer
    } );

    // Show report button after all pointers are processed
    const reportButtonTimer = setTimeout( () => {
      setShowReportButton( true );
    }, ( pointers.length + 1 ) * 3000 );

    timers.push( reportButtonTimer );

    return () => {
      timers.forEach( ( timer ) => clearTimeout( timer ) );
    };
  }, [pointers] );

  const newURL = `https://reports.strideahead.io/${ userAssessmentResponseID }?user=${ userName }&product_code=${ productCode }&email=${ savedEmail }`;

  const pointersList = pointers.map( ( pointer, index ) => (
    <div key={ index } className="flex item-start">
      <img src={ enableIcons[index] ? EnableTick : DisableTick } className="w-6 h-6" alt="Tick Icon" />
      <p className={ `font-poppins pl-2 text-base font-normal ${ enableIcons[index] ? 'text-black' : 'text-[#706E84]' } sm:text-xs md:text-sm` }>{ pointer }</p>
    </div>
  ) );

  const proceedToMobileVerification = () => {
    dispatch( setIsFormSubmitted( true ) )
    localStorage.setItem( 'isFormSubmitted', true )
  }

  return (
    <>
      <div className="font-poppins w-full flex flex-col items-center justify-center">
        <div className="text-center">
          <img src={ LoaderAnimation } className="mx-auto sm:w-[12.5rem] lg:w-[22.5rem]" alt="SheeHulk" />
        </div>
        <div className="text-center">
          <h1 className="mb-4 text-lg font-semibold text-[#352958] sm:text-xl md:text-2xl">{ heading }</h1>
          { pointersList }
        </div>
      </div>

      { showReportButton && (
        <div className="flex items-center flex-col animate-slideUp font-poppins">
          { client_name === "strideSurvey" ? (
            <Link to="/strideSurvey/thank_you">
              <button
                className="px-4 py-2 rounded-md border-2 text-white bg-blue-500 transition-transform transform hover:translate-y-[-5px]"
              >
                Go to Reports
              </button>
            </Link>
          ) : ( client_name === "icat" || client_name === "jc" ) ? (
            <button
              className="px-4 py-2 rounded-md border-2 text-white bg-blue-500 transition-transform transform hover:translate-y-[-5px]"
              onClick={ proceedToMobileVerification }
            >
              Access Report!
            </button>
          ) : (
            <button
              className="px-4 py-2 rounded-md border-2 text-white bg-blue-500 transition-transform transform hover:translate-y-[-5px]"
              onClick={ () => {
                window.location.href = newURL;
              } }
            >
              Go to Reports
            </button>
          ) }
        </div>
      ) }
    </>
  );
};

export default Review;
