import React, { useState, useEffect, useRef } from "react";
import { Image, Spin, notification, Modal, Button } from 'antd';
import { questionsConfig } from "./questionsConfig";
import Review from "./ReviewPage/Review";
import QuestionStep from "./SingleChoiceQuestions/SingleChoiceStep";
import MultiChoiceStep from "./MultipleChoiceQuestions/MultiChoiceStep";
import DropDownStepMultipleSelect from "./DropDownQuestions/DropDownStepMultipleSelect";
import DropDownStepSingleSelect from "./DropDownQuestions/DropDownStepSingleSelect";
import TextInputStep from "./InputTypeText/TextInputStep";
import QuestionStepValidation from "./ValidationTypeQuestions/QuestionStepValidation";
import NumberInputStep from "./InputTypeNumber/NumberInputStep";
import PhoneNumberInputStep from "./InputTypeNumber/PhoneNumberInputStep";
import UploadFileInputType from "./UploadFileType/UploadFileTypeStep";
import EmailTypeStep from "./EmailTypeQuestion/EmailTypeStep";
import StateSelectionQuestion from "./StateSelectionQuestion/StateSelectionQuestion";
import { InfoCircleFilled } from '@ant-design/icons'
import "../css/bootstrap.min.css";
import "../css/bootstrap.css";
import "../css/style.css";
import { useMedia } from 'react-use';
import ProgressBar from "@ramonak/react-progress-bar";
import useFetchQuestion from "../api/FetchQuestions";
import useCreateUserAssessmentResponse from "../api/CreateUserAccessment";
import useCreateUserQuestionResponse from "../api/CreateUserQuestionResponse";
import { useParams, useNavigate, useLocation, unstable_usePrompt } from 'react-router-dom';
import MobileVerification from "./Verification/PhoneNumberVerification";
import EmailVerification from "./Verification/EmailVerification";
import LeftSideBar from "./LeftSideBar";
import AdvertiseMentModal from "../helpers/AdvertisementModal";
import { setIsConsentForm, resetState, setClientConfiguration, setFormConfiguration, updateAnswerIndex, resetAnswerStatus, setQuestionNumber, nextFlow, setIsRequiredQuestionsAttempted } from '../redux/student/studentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { dynamicData, imageQuestion } from "../helpers/data";
import NameInputStep from "./InputTypeText/NameTypeQuestion";
import DobInputType from "./DobInputQuestion/DobInputQuestion";
import ThankYouPage from "./ThankYouPage/ThankYou";
import FlexibleInputStep from "./FlexibleInputStep/FlexibleInputStep";
import IndianStateSelectionQuestion from "./StateSelectionQuestion/IndianStateSelectionQuestion";
import IndianCitiesSelectionQuestion from "./StateSelectionQuestion/IndianCitiesSelectionQuestion";
import TestTimer from "./TestTimer/countDown";
import { current } from "@reduxjs/toolkit";
import TestTermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import { TERMS_AND_CONDITIONS, ASKIITIAN_TERMS_AND_CONDITIONS } from "../utils/constants/constants";
import ImageQuestionStep from "./ImageTypeQuestion/QuestionImage";
import updateUserResponse from "../api/useUpdateUserResponse";
import Lottie from 'lottie-react';
import animationData from '../assets/Animation/loading_animation.json';
import RangeTypeQuestion from "./RangeTypeQuestion/RangeTypeQuestion";
import OrderTypeQuestion from "./OrderTypeQuestion/OrderTypeQuestion";
import { useEvent } from 'react-use';
import PerQuestionTimer from "./TestTimer/PerQuestionTimer";
import useCreateSchoolCode from "../api/CreateSchoolCode";
import useCreateStudentCode from "../api/CreateStudentCode";
import useFetchClientConfig from '../api/FetchClientConfig';
import useFetchFormConfig from "../api/FetchFormConfig";
import useMultipleQuestionResponses from "../api/useMultipleQuestionResponses";
import TestSubmissionPage from "./TestSubmission/TestSubmission";
import './DataTables.css';
import useFetchFlowBlocks from "../api/FetchFlowBlocks";
import useFetchStudentDataEmail from "../api/FetchStudentDetailsEmail";
import useUpdateUserQuestionResponse from "../api/UpdateUserQuestionResponse";
import { Helmet } from 'react-helmet';
import InstructionsModalTest from "./InstructionPage/InstructionsModalTest";
import IndustrySelector from "./IndustrySelector/IndustrySelector";
import SkillSelector from "./SkillSelector/SkillSelector";
import useUpdateMultipleQuestionResponse from "../api/useUpdateMultipleQuestionResponse";
import useBlockNavigation from "../helpers/NavigationBlocker";
import SigninCouponCode from "./SignInPage/SigninCouponCode";
import AuthModal from "../helpers/AuthModal";
import { useBlocker } from "react-router-dom";
import AddressTypeQuestion from "./AddressTypeQuestion/AddressTypeQuestion";
import SubjectSelectionQuestion from "./SubjectsTypeQuestion/SubjectTypeQuestion";

const MultiStepForm = () => {
    const containerRef = useRef( null );

    const [pendingApiData, setPendingApiData] = useState( null );
    const [pendingApiDataMultiChoice, setPendingApiDataMultiChoice] = useState( null );
    const [pendingApiDataDropDown, setPendingApiDataDropDown] = useState( null );
    const [pendingApiTextInput, setPendingApiTextInput] = useState( null );
    const [pendingApiNumberInput, setPendingApiNumberInput] = useState( null );
    const [pendingApiUploadFile, setPendingApiUploadFile] = useState( null );
    const [pendingApiEmailInput, setPendingApiEmailInput] = useState( null );
    const [pendingApiVerification, setPendingApiVerification] = useState( null );
    const [pendingStateQuestion, setPendingStateQuestion] = useState( null );
    const [pendingOrderType, setPendingOrderType] = useState( null );
    const [pendingApiRangeType, setPendingApiRangeType] = useState( null );
    const [pendingApiAddressType, setPendingApiAddressType] = useState( null );
    const [showProgressBar, setShowProgressBar] = useState( true )
    const [isClientActiveModal, setIsClientActiveModal] = useState( false );

    const [isTestAlreadyGiven, setIsTestAlreadyGiven] = useState( false );
    const [refreshTest, setRefreshTest] = useState( false );

    const [isUserRegistered, setIsUserRegistered] = useState( true );
    const [backUpdateQuestionsResponse, setBackUpdateQuestionsResponse] = useState( false );

    const [instructionModalTest, setInstructionModalTest] = useState( false );

    const [answerResponseid, setAnswerResponseid] = useState( [] );
    const [questionId, setQuestionId] = useState( [] );

    const dispatch = useDispatch();
    const questionNumber = useSelector( state => state.currentQuestion.questionNumber );
    const isConsentForm = useSelector( state => state.consentForm.isConsentForm )
    const isFormSubmitted = useSelector( state => state.formSubmitted.isFormSubmitted );
    const isLoadingScreen = useSelector( state => state.loadingScreen.isLoadingScreen );
    const formConfig = useSelector( state => state.formConfiguration );
    const clientConfig = useSelector( state => state.clientConfiguration );
    const textAnswersReduxState = useSelector( ( state ) => state.answersSlice.textAnswers );

    const isRequiredQuestionsAttempted = useSelector( state => state.requiredQuestionsAttempted.isRequiredQuestionsAttempted )

    const isClientActive = clientConfig?.is_active;

    const [isAllAnswersValid, setIsAllAnswersValid] = useState( false );

    const [isModalOpen, setIsModalOpen] = useState( false );
    const [userType, setUserType] = useState( '' );

    const [dynamicQuestions, setDynamicQuestions] = useState( [] );

    const [textAnswers, setTextAnswers] = useState( [] );

    //const [currentDynamicStep, setCurrentDynamicStep] = useState( 0 );
    const { client_name, grade, user_type, test_type, testId } = useParams();

    // const isQuestionNavigation = test_type === 'test' ? true : false;

    const clientConfiguration = sessionStorage.getItem( 'clientConfiguration' );
    const formConfiguration = sessionStorage.getItem( 'formConfiguration' );

    const { response: updateUserResponseData, isLoading: updateUserResponseLoading, error: updateUserResponseError, updateResponse } = updateUserResponse();

    const { response: studentData, isLoading: studentDataLoading, error: studentDataError, FetchStudentDataEmail } = useFetchStudentDataEmail();

    const { response: flowBlocks, isLoading: flowLoading, FetchFlowBlocks } = useFetchFlowBlocks();

    useEffect( () => {
        if ( testId ) {
            FetchFlowBlocks( testId );
        }
    }, [testId] )

    const { response: clientConfigData, isLoading, fetchClientConfig } = useFetchClientConfig();
    const { response: formConfigData, isLoading: formConfigLoading, fetchFormConfig } = useFetchFormConfig();

    useEffect( () => {
        if ( client_name && !clientConfiguration ) {
            fetchClientConfig( client_name );
        }
    }, [client_name] )

    useEffect( () => {
        localStorage.setItem( 'testId', testId );
    }, [testId] )

    useEffect( () => {
        if ( client_name && !formConfiguration && isClientActive ) {
            fetchFormConfig( client_name, test_type, testId );
        }
    }, [client_name, isClientActive] )

    useEffect( () => {
        if ( clientConfig && !isClientActive && isClientActive !== undefined ) {
            setIsClientActiveModal( true );
        }
    }, [isClientActive, clientConfig] )

    useEffect( () => {
        if ( clientConfigData && clientConfigData?.data ) {
            dispatch( setClientConfiguration( clientConfigData.data ) );
            sessionStorage.setItem( 'clientConfiguration', JSON.stringify( clientConfigData.data ) );

            const timeout = setTimeout( () => {
                sessionStorage.removeItem( 'clientConfiguration' );
                fetchClientConfig( client_name );
            }, 10 * 60 * 1000 );
            return () => clearTimeout( timeout );
        }
    }, [clientConfigData, client_name] );

    useEffect( () => {
        if ( formConfigData && formConfigData?.data?.form_config ) {
            dispatch( setFormConfiguration( formConfigData?.data ) );
            sessionStorage.setItem( 'formConfiguration', JSON.stringify( formConfigData?.data ) );

            const timeout = setTimeout( () => {
                sessionStorage.removeItem( 'formConfiguration' );
                fetchFormConfig( client_name, test_type, testId );
            }, 60 * 60 * 1000 );
            return () => clearTimeout( timeout );
        }
    }, [formConfigData, formConfigLoading] )

    const data = formConfigData?.data ? formConfigData?.data?.form_data : formConfig?.form_data;
    const form_config_data =
        formConfigData?.data ? formConfigData?.data?.form_config : formConfig?.form_config;


    const form_type = data?.form_type;
    const template_id = data?.pdf_template_name;
    const form_id = form_config_data?.form_id;
    const batchSize = form_config_data?.questions_per_page || '';
    const form_name = data?.name;
    const instructions = form_config_data?.instructions;
    const isQuestionNavigation = form_config_data?.is_question_navigation
    const formTimer = form_config_data?.form_timer;

    const [addedFollowUps, setAddedFollowUps] = useState( [] );
    const [email, setEmail] = useState( '' );
    const [name, setName] = useState( '' );
    const [phoneNumber, setPhoneNumber] = useState();
    const [showAdvertisement, setShowAdvertisement] = useState( false );
    const { response: multipleAnswerResponse, updateQuestionResponse, multipleQuestionResponses } = useMultipleQuestionResponses( batchSize );
    const { updateMultipleQuestionResponse, updateMultipleQuestionResponseCall } = useUpdateMultipleQuestionResponse( batchSize );
    const student_grade_local_storage = localStorage.getItem( 'student_grade' );
    //console.log( "questionNumberCircle2", questionNumber );

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState( false );

    useEffect( () => {
        // Example logic to determine if there are unsaved changes
        // Replace this with your actual logic
        const checkForUnsavedChanges = () => {
            // Set hasUnsavedChanges to true if there are unsaved changes
            setHasUnsavedChanges( true );
        };

        // Add event listener or other logic to check for unsaved changes
        // For example, you can listen to form input changes or other state changes
        checkForUnsavedChanges();
    }, [] );

    useBlockNavigation( hasUnsavedChanges );

    const resetAdvertisement = () => {
        setShowAdvertisement( false );
    };

    const handleProceedToQuiz = () => {
        dispatch( setIsConsentForm( false ) );
    };

    const navigate = useNavigate();
    const location = useLocation();

    const { test_series_id } = location.state || {};
    const currentQueryParams = location.search;

    const params = new URLSearchParams( location.search );
    const utmSource = params.get( 'utm_source' );
    const utmMedium = params.get( 'utm_medium' );
    const utmCampaign = params.get( 'utm_campaign' );
    const userEmail = params.get( 'user_email' );
    const userName = params.get( 'user_name' );
    const studentRollNumber = params.get( 'user_id' );
    const studentPhone = params.get( 'phone' );
    const retakeTest = params.get( 'retake' );

    useEffect( () => {
        if ( !isQuestionNavigation ) return;
        if ( userEmail ) {
            FetchStudentDataEmail( userEmail, client_name )
        } else if ( !userEmail ) {
            setIsUserRegistered( false );
        }
    }, [userEmail, userName, formConfigData] )

    const [workFlowList, setWorkFlowList] = useState( [] );
    const [currentComponent, setCurrentComponent] = useState( 0 );
    const [currentFlowName, setCurrentFlowName] = useState( "" )

    useEffect( () => {
        let workflowList = [];
        if ( flowBlocks ) {
            workflowList = flowBlocks.map( item => item.block_type );
        }
        setWorkFlowList( workflowList );
    }, [flowBlocks] );

    useEffect( () => {
        if ( workFlowList?.length > 0 ) {
            setCurrentFlowName( workFlowList[currentComponent] );
        }
    }, [currentComponent, workFlowList] );

    useEffect( () => {
        if ( clientConfigData ) {
            document.title = clientConfigData?.data?.tag_line;
            document.querySelector( 'meta[name="description"]' ).setAttribute( "content", clientConfigData?.data?.description );
        }
    }, [clientConfigData] );

    const terms_and_conditions = dynamicData[form_config_data?.client_id]?.terms_and_conditions || "";

    //const isCouponCodeVerification = form_config_data?.coupon_code_verification || '';
    const viewReport = form_config_data?.view_report || '';

    //const is_mobile_verification = form_config_data?.is_mobile_verification || '';
    //const is_email_verification = form_config_data?.is_email_verification || '';
    //const is_Loading_Screen = form_config_data?.is_loading_screen || '';
    const isPagination = form_config_data?.is_pagination || '';

    const testTimeInSeconds = form_config_data?.overall_timer || '';

    const isLeftSidebarVisible = form_config_data?.is_left_sidebar_visible || ''

    const school_registration_route = false;
    const student_registration_route = true;

    useEffect( () => {
        let productCode = "";
        if ( user_type === 'college' ) {
            productCode = "COLLEGE_SURVEY";
        } else if ( user_type === 'workingprofessional' ) {
            productCode = "PROFESSIONAL_SURVEY";
        }
        localStorage.setItem( 'product_code', productCode );
    }, [user_type] )

    //const { data, isLoading, isError } = useFetchQuestion( form_id );

    const { createSchoolCode, response: createSchoolResponse, error: schoolError, isLoading: creatingSchool } = useCreateSchoolCode();
    const { createStudentCode, response: createStudentResponse, error: studentError, isLoading: creatingStudent } = useCreateStudentCode();

    const [userAssessmentResponseID, setUserAssessmentResponseID] = useState(
        localStorage.getItem( 'userAssessmentResponseID' )
    );

    useEffect( () => {
        if ( data?.questions ) {
            setDynamicQuestions( [...data.questions] );
        }
    }, [data] );

    useEvent( 'keydown', ( e ) => {
        if ( e.key === 'Tab' ) {
            e.preventDefault();
        }
    } );


    const [validations, setValidations] = useState( [] );

    useEffect( () => {
        if ( dynamicQuestions?.length && isPagination ) {
            const initialBatchSize = Math.min( batchSize, dynamicQuestions?.length );
            setValidations( Array.from( { length: initialBatchSize }, () => ( {} ) ) )
        }
    }, [dynamicQuestions?.length] );

    //const userId = localStorage.getItem( 'user_id' ) || localStorage.getItem( 'student_roll_number' ) || 0;

    const userId = studentRollNumber;

    const is_completed = localStorage.getItem( 'is_completed' ) || 1;

    const { response, error, CreateUserAssessmentResponse } = useCreateUserAssessmentResponse()

    const createAccessmentIdTest = () => {
        if ( data && studentData && !response ) {
            dispatch( setQuestionNumber( 0 ) );
            if ( !studentData?.student_code ) {
                dispatch( resetState() );
            }
            if ( userId ) {
                CreateUserAssessmentResponse( userId, form_id, is_completed, testId, retakeTest );
            } else if ( !userId && studentData ) {
                const newUserid = studentData?.student_code || studentData?.id;
                CreateUserAssessmentResponse( newUserid, form_id, is_completed, testId, retakeTest );
            } else if ( !userId && !studentData?.student_code ) {
                CreateUserAssessmentResponse( 0, form_id, is_completed, testId, retakeTest );
            }
        }
    }

    useEffect( () => {
        createAccessmentIdTest();
    }, [data, studentData] )

    const createAccessmentIdSurvey = () => {
        if ( data && !isQuestionNavigation && !response ) {
            dispatch( setQuestionNumber( 0 ) );
            dispatch( resetState() );
            CreateUserAssessmentResponse( userId, form_id, is_completed, testId, retakeTest );
        }
    }

    useEffect( () => {
        createAccessmentIdSurvey();
    }, [data, form_id] )

    useEffect( () => {
        if ( response )
            if ( userAssessmentResponseID === null && form_type === "survey" ) {
                localStorage.setItem( 'userAssessmentResponseID', response.id );
            } else if ( userAssessmentResponseID === null && form_type === "test" ) {
                localStorage.setItem( 'userAssessmentResponseID', response.id );
            } else if ( userAssessmentResponseID !== null && form_type === "survey" ) {
                localStorage.setItem( 'userAssessmentResponseID', response.id );
            } else if ( userAssessmentResponseID !== null && form_type === "test" ) {
                localStorage.setItem( 'userAssessmentResponseID', response.id );
            }
        setUserAssessmentResponseID( localStorage.getItem( 'userAssessmentResponseID' ) )
    }, [response] )

    useEffect( () => {
        if ( response && response?.is_completed === 3 ) {
            setIsTestAlreadyGiven( true );
        }
    }, [response] )

    // useEffect( () => {
    //     if ( userEmail && userName && response && studentPhone && ( !studentData?.student_code && !studentData?.id ) ) {
    //         createStudentCode( userName, "", userEmail, client_name, response.id, "", form_config_data?.client_id, studentPhone )
    //     }
    // }, [response, studentData] );


    useEffect( () => {
        if ( userEmail && userName && response && ( !studentData?.student_code && !studentData?.id ) ) {
            createStudentCode( userName, "", userEmail, client_name, response.id, "", form_config_data?.client_id, studentPhone || "" )
        }
    }, [response, studentData] );

    useEffect( () => {
        if ( studentData && studentData?.student_code ) {
            localStorage.setItem( 'student_roll_number', studentData?.student_code );
        }
    }, [studentData] )


    useEffect( () => {
        if ( !isConsentForm ) {
            updateResponse( { id: userAssessmentResponseID, is_completed: 2, clientName: client_name } )
        }
    }, [isConsentForm, userAssessmentResponseID] )

    useEffect( () => {
        if ( response && response?.is_completed === 2 && testTimeInSeconds === 0 ) {
            setRefreshTest( false );
        } else if ( response && response?.is_completed === 2 && testTimeInSeconds > 0 ) {
            setRefreshTest( true );
        }
    }, [response] )


    useEffect( () => {
        if ( dynamicQuestions ) {
            const ids = dynamicQuestions.map( question => question.id );
            setQuestionId( ids );
        }
    }, [dynamicQuestions] )

    useEffect( () => {
        if ( userAssessmentResponseID && data ) {
            const storedTextAnswers = localStorage.getItem( `textAnswers_${ form_id }_${ userAssessmentResponseID }` );
            if ( storedTextAnswers ) {
                setTextAnswers( JSON.parse( storedTextAnswers ) );
            }

            const initialTextAnswers = Array( data.questions.length ).fill( '' );
            setTextAnswers( initialTextAnswers )

            if ( response && response?.question_responses?.length > 0 ) {

                // Initialize an array to store the answer response IDs in the correct order
                const updatedAnswerResponseIds = [...answerResponseid];
                const updatedTextAnswers = [...textAnswers]; // Clone initialTextAnswers array to update later

                // Map over response.question_responses and find the correct index in questionId array
                response.question_responses.forEach( ( questionResponse ) => {
                    const optionIds = questionResponse.options.map( ( option ) => option.option_id );
                    const questionIdFromResponse = questionResponse.question_id;
                    const textResponse = questionResponse.text_response;

                    // Find the index of the question_id in the questionId array
                    const questionIndex = questionId.indexOf( questionIdFromResponse );

                    // If the question exists in questionId, update textAnswers and answerResponseid at the correct position
                    if ( questionIndex !== -1 ) {
                        // Update textAnswers with optionIds
                        updatedTextAnswers[questionIndex] = optionIds.length === 1
                            ? optionIds[0]  // Save as integer if there's one option
                            : optionIds.length > 1
                                ? optionIds  // Save as array if there are multiple options
                                : textResponse;  // Empty if no options

                        // Update answerResponseid with the response ID for the same index
                        updatedAnswerResponseIds[questionIndex] = questionResponse.id;

                        // Dispatch the updateAnswerIndex action only if questionResponse.options has more than 0 options
                        if ( questionResponse.options.length > 0 || textResponse !== "" ) {
                            dispatch( updateAnswerIndex( questionIndex ) );
                        }
                    }
                } );

                // Update states with the modified textAnswers and answerResponseid
                setTextAnswers( updatedTextAnswers );
                setAnswerResponseid( updatedAnswerResponseIds );
            }
        }
    }, [data, form_id, userAssessmentResponseID, response] );

    useEffect( () => {
        if ( !userAssessmentResponseID ) {
            // No ID in localStorage, make API call
            if ( response?.id && localStorage.getItem( 'userAssessmentResponseID' ) === null ) {
                localStorage.setItem( 'userAssessmentResponseID', response.id );
                setUserAssessmentResponseID( response.id );
            }
        }
        localStorage.setItem( 'form_id', form_id );
        localStorage.setItem( 'clientId', form_config_data?.client_id );
    }, [response, form_id, userAssessmentResponseID] );

    // Function to get the stored currentDynamicStep value from localStorage
    const getStoredCurrentDynamicStep = () => {
        const storedStep = localStorage.getItem( `currentDynamicStep_${ form_id }` );
        return storedStep ? parseInt( storedStep, 10 ) : 0;
    };

    //const [currentDynamicStep, setCurrentDynamicStep] = useState( getStoredCurrentDynamicStep() );
    const [currentDynamicStep, setCurrentDynamicStep] = useState( 0 );

    useEffect( () => {
        setCurrentDynamicStep( questionNumber );
    }, [questionNumber] );

    useEffect( () => {
        // Update the stored currentDynamicStep whenever it changes
        dispatch( setQuestionNumber( currentDynamicStep ) );
    }, [currentDynamicStep] );

    useEffect( () => {
        // Check if textAnswers is non-empty before updating local storage
        if ( textAnswers.length > 0 && userAssessmentResponseID !== null ) {
            localStorage.setItem( `textAnswers_${ form_id }_${ userAssessmentResponseID }`, JSON.stringify( textAnswers ) );
        }
    }, [textAnswers, form_id, userAssessmentResponseID] );

    useEffect( () => {
        // Check if textAnswers is non-empty before updating local storage
        if ( questionId.length > 0 && userAssessmentResponseID !== null ) {
            localStorage.setItem( `questionId_${ form_id }_${ userAssessmentResponseID }`, JSON.stringify( questionId ) );
        }
    }, [textAnswers, form_id, userAssessmentResponseID] );


    const { createUserQuestionResponse, response: answerResponse } = useCreateUserQuestionResponse();

    const { updateUserQuestionResponse } = useUpdateUserQuestionResponse();

    const [dateOfbirth, setDateOfbirth] = useState( Array( 10 ).fill( '' ) );

    useEffect( () => {
        if ( answerResponse ) {
            let newAnswerResponses = [...answerResponseid];
            newAnswerResponses[currentDynamicStep - 1] = answerResponse.id;
            setAnswerResponseid( newAnswerResponses );
        }
    }, [answerResponse] )

    useEffect( () => {
        if ( multipleAnswerResponse ) {
            const ids = multipleAnswerResponse.map( response => response.id );
            setAnswerResponseid( ids );
        }
    }, [multipleAnswerResponse] )

    const handleSingleChoiceSelect = ( option, currentDynamicStep, index ) => {

        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = option;
        setTextAnswers( newTextAnswers );

        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;
        const selectedOptionId = option;


        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = "";
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = [selectedOptionId];
            } );
        }

        if ( isPagination && answerResponseid[index] ) {
            updateMultipleQuestionResponse( index, response => {
                response.id = answerResponseid[index];
                response.text_response = "";
                response.options = [selectedOptionId];;
            } );
        }

        const updatedDynamicQuestions = [...dynamicQuestions];
        const prevFollowUpIndex = addedFollowUps[currentDynamicStep];

        // If there was a previous follow-up question, remove it
        if ( prevFollowUpIndex !== undefined ) {
            updatedDynamicQuestions.splice( prevFollowUpIndex, 1 );
            setAddedFollowUps( { ...addedFollowUps, [currentDynamicStep]: undefined } );
        }

        // If the new option has a follow-up question, add it
        if ( option?.follow_up_question ) {
            updatedDynamicQuestions.splice( currentDynamicStep + 1, 0, option.follow_up_question );
            setAddedFollowUps( { ...addedFollowUps, [currentDynamicStep]: currentDynamicStep + 1 } );

        }
        setDynamicQuestions( updatedDynamicQuestions );
    };

    const handlesingleChoiceClearAll = () => {
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = ''; // Clear the selected option
        setTextAnswers( newTextAnswers );

        if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
            dispatch( resetAnswerStatus( currentDynamicStep ) );
            updateUserQuestionResponse( [], '', answerResponseid[currentDynamicStep] )
        }
    };

    // console.log( 'answerResponseid', answerResponseid );
    // console.log( 'qustionId', questionId );

    const handleSingleChoiceNext = () => {
        const currentQuestionID = questionId[currentDynamicStep];
        const selectedOptionId = textAnswers[currentDynamicStep];
        const selectedOptionIdArray = [selectedOptionId]

        if ( selectedOptionIdArray?.length > 0 && Number.isInteger( selectedOptionId ) ) {
            // Dispatch updateAnswerIndex if selectedOptionId is not empty
            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( selectedOptionId, '', answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, selectedOptionId );
            }
            dispatch( updateAnswerIndex( currentDynamicStep ) );
        } else {
            // Dispatch resetAnswerStatus if selectedOptionId is empty
            dispatch( resetAnswerStatus( currentDynamicStep ) );
        }
        setCurrentDynamicStep( currentDynamicStep + 1 );
    };

    const handleMultipleChoiceSelect = ( selectedOptionIds, currentDynamicStep, index ) => {
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = selectedOptionIds;
        setTextAnswers( newTextAnswers );

        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = "";
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = selectedOptionIds.map( optionId => ( { option_id: optionId } ) );
            } );
        }

        if ( isPagination && answerResponseid[index] ) {
            updateMultipleQuestionResponse( index, response => {
                response.id = answerResponseid[index];
                response.text_response = "";
                response.options = selectedOptionIds.map( optionId => ( { option_id: optionId } ) );
            } );
        }

        // Populate pendingApiDataMultiChoice
        setPendingApiDataMultiChoice( {
            currentQuestionID,
            userAssessmentResponseID,
            selectedOptionIds,
        } );



        // This will be the updated list of dynamic questions after insertion/removal of follow-ups
        let updatedDynamicQuestions = [...dynamicQuestions];

        // Identify the follow-up questions that are currently in the dynamicQuestions list
        const currentFollowUps = updatedDynamicQuestions.slice( currentDynamicStep + 1 )
            .filter( q => q.isFollowUp ); // Assuming isFollowUp is a property you set when adding follow-ups

        // Remove all current follow-up questions related to the current step
        updatedDynamicQuestions = updatedDynamicQuestions.filter( q => !q.isFollowUp || !currentFollowUps.includes( q ) );

        // Now, determine which follow-up questions to add based on the newly selected options
        selectedOptionIds.forEach( id => {
            const option = dynamicQuestions[currentDynamicStep].options.find( o => o.id === id );
            if ( option && option.follow_up_question ) {
                // Mark the question as a follow-up for easier identification
                const followUpQuestion = { ...option.follow_up_question, isFollowUp: true };
                updatedDynamicQuestions.splice( currentDynamicStep + 1, 0, followUpQuestion );
            }
        } );

        // Update the dynamicQuestions state with the new questions list
        setDynamicQuestions( updatedDynamicQuestions );
    };

    const handleMultiChoiceNext = () => {
        if ( pendingApiDataMultiChoice ) {
            const { currentQuestionID, userAssessmentResponseID, selectedOptionIds } = pendingApiDataMultiChoice;
            if ( selectedOptionIds.length > 0 ) {
                // Dispatch updateAnswerIndex if selectedOptionId is not empty
                dispatch( updateAnswerIndex( currentDynamicStep ) );
            } else if ( selectedOptionIds.length === 0 ) {
                // Dispatch resetAnswerStatus if selectedOptionId is empty
                dispatch( resetAnswerStatus( currentDynamicStep ) );
            }

            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( selectedOptionIds, '', answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, selectedOptionIds );
            }
            setPendingApiDataMultiChoice( null );
        }
        setCurrentDynamicStep( currentDynamicStep + 1 );
    };

    const handleDropdownSelect = ( selectedValues, currentDynamicStep, index ) => {
        // Ensure selectedValues is an array
        const normalizedSelectedValues = Array.isArray( selectedValues ) ? selectedValues : [selectedValues];

        // Filter numeric options and string options
        const selectedIds = normalizedSelectedValues.filter( option => typeof option === 'number' );
        const selectedTexts = normalizedSelectedValues.filter( option => typeof option === 'string' );
        const normalizedId = selectedTexts.length > 0 ? selectedTexts[0] : '';  // Take the first string, if any

        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = normalizedSelectedValues;  // Use normalized array
        setTextAnswers( newTextAnswers );

        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;


        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = normalizedId;
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = selectedIds.map( optionId => ( { option_id: optionId } ) );
            } );
        }

        if ( isPagination && answerResponseid[index] ) {
            updateMultipleQuestionResponse( index, response => {
                response.id = answerResponseid[index];
                response.text_response = normalizedId;
                response.options = selectedIds.map( optionId => ( { option_id: optionId } ) );
            } );
        }

        setPendingApiDataDropDown( {
            currentQuestionID,
            userAssessmentResponseID,
            selectedValues: selectedIds,
            textResponse: normalizedId,
        } );

        // This will be the updated list of dynamic questions after insertion/removal of follow-ups
        let updatedDynamicQuestions = [...dynamicQuestions];

        // Identify the follow-up questions that are currently in the dynamicQuestions list
        const currentFollowUps = updatedDynamicQuestions.slice( currentDynamicStep + 1 )
            .filter( q => q.isFollowUp ); // Assuming isFollowUp is a property you set when adding follow-ups

        // Remove all current follow-up questions related to the current step
        updatedDynamicQuestions = updatedDynamicQuestions.filter( q => !q.isFollowUp || !currentFollowUps.includes( q ) );

        // Now, determine which follow-up questions to add based on the newly selected options
        normalizedSelectedValues.forEach( id => {
            const option = dynamicQuestions[currentDynamicStep].options.find( o => o.id === id );
            if ( option && option.follow_up_question ) {
                // Mark the question as a follow-up for easier identification
                const followUpQuestion = { ...option.follow_up_question, isFollowUp: true };
                updatedDynamicQuestions.splice( currentDynamicStep + 1, 0, followUpQuestion );
            }
        } );

        // Update the dynamicQuestions state with the new questions list
        setDynamicQuestions( updatedDynamicQuestions );
    };

    const handleCourseSelect = ( answer, currentDynamicStep, index ) => {
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = answer;
        setTextAnswers( newTextAnswers );

        // Get the current question ID
        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = answer;
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = [];
            } );
        }

        if ( isPagination && answerResponseid[index] ) {
            updateMultipleQuestionResponse( index, response => {
                response.id = answerResponseid[index];
                response.text_response = answer;
                response.options = [];
            } );
        }


        setPendingApiDataDropDown( {
            currentQuestionID,
            userAssessmentResponseID,
            textResponse: answer
        } );
    };

    const handleDropDownNext = () => {
        // Make API call using pendingApiDataMultiChoice if it exists
        if ( pendingApiDataDropDown ) {
            const { currentQuestionID, userAssessmentResponseID, selectedValues, textResponse } = pendingApiDataDropDown;
            if ( selectedValues && selectedValues.length > 0 || textResponse ) {
                // Dispatch updateAnswerIndex if selectedOptionId is not empty
                dispatch( updateAnswerIndex( currentDynamicStep ) );
            } else if ( ( !selectedValues || selectedValues.length === 0 ) || !textResponse ) {
                // Dispatch resetAnswerStatus if selectedOptionId is empty
                dispatch( resetAnswerStatus( currentDynamicStep ) );
            }

            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( selectedValues, textResponse, answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, selectedValues, textResponse );
            }
            setPendingApiDataDropDown( null );
        }
        setCurrentDynamicStep( currentDynamicStep + 1 );
    };


    const handleTextAnswerChange = ( answer, currentDynamicStep, index ) => {
        // Update the local state for text answers
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = answer;
        setTextAnswers( newTextAnswers );

        // Get the current question ID
        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = answer;
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = [];
            } );
        }

        if ( isPagination && answerResponseid[index] ) {
            updateMultipleQuestionResponse( index, response => {
                response.id = answerResponseid[index];
                response.text_response = answer;
                response.options = [];
            } );
        }


        // Populate pendingApiTextInput
        setPendingApiTextInput( {
            currentQuestionID,
            userAssessmentResponseID,
            textResponse: answer
        } );
    };


    const handleTextNext = () => {
        const currentQuestionID = questionId[currentDynamicStep];
        const textResponse = textAnswers[currentDynamicStep];

        if ( textResponse !== "" ) {

            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( [], textResponse, answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], textResponse );
            }

            dispatch( updateAnswerIndex( currentDynamicStep ) );
        } else {
            // Dispatch resetAnswerStatus if selectedOptionId is empty
            dispatch( resetAnswerStatus( currentDynamicStep ) );
        }

        setCurrentDynamicStep( currentDynamicStep + 1 );
    };

    const handleNameAnswerChange = ( answer, currentDynamicStep, index ) => {
        // Update the local state for text answers
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = answer;
        setTextAnswers( newTextAnswers );

        // Get the current question ID
        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = answer;
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = [];
            } );
        }

        if ( isPagination && answerResponseid[index] ) {
            updateMultipleQuestionResponse( index, response => {
                response.id = answerResponseid[index];
                response.text_response = answer;
                response.options = [];
            } );
        }


        // Populate pendingApiTextInput
        setPendingApiTextInput( {
            currentQuestionID,
            userAssessmentResponseID,
            textResponse: answer
        } );
    };

    const handleNameNext = () => {
        // Make API call using pendingApiTextInput if it exists
        if ( pendingApiTextInput ) {
            const { currentQuestionID, userAssessmentResponseID, textResponse } = pendingApiTextInput;

            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( [], textResponse, answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], textResponse );
            }
            localStorage.setItem( 'user_name', textResponse );
            setPendingApiTextInput( null );  // Clear pendingApiTextInput
        }
        setCurrentDynamicStep( currentDynamicStep + 1 );
    };


    const handleNumberTextAnswerChange = ( answer, currentDynamicStep, index ) => {
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = answer;
        setTextAnswers( newTextAnswers );

        // Get the current question ID
        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = answer;
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = [];
            } );
        }

        if ( isPagination && answerResponseid[index] ) {
            updateMultipleQuestionResponse( index, response => {
                response.id = answerResponseid[index];
                response.text_response = answer;
                response.options = [];
            } );
        }

        // // Populate pendingApiNumberInput
        // setPendingApiNumberInput( {
        //     currentQuestionID,
        //     userAssessmentResponseID,
        //     numberTextResponse: answer
        // } );
    };

    const handleNumberTextNext = () => {
        const currentQuestionID = questionId[currentDynamicStep];
        const numberTextResponse = textAnswers[currentDynamicStep];

        if ( numberTextResponse !== "" ) {

            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( [], numberTextResponse, answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], numberTextResponse );
            }

            dispatch( updateAnswerIndex( currentDynamicStep ) );
        } else {
            dispatch( resetAnswerStatus( currentDynamicStep ) );
        }

        setCurrentDynamicStep( currentDynamicStep + 1 );
    };


    const handleEmailType = ( answer, currentDynamicStep, index ) => {
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = answer;
        setTextAnswers( newTextAnswers );

        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = answer;
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = [];
            } );
        }

        if ( isPagination && answerResponseid[index] ) {
            updateMultipleQuestionResponse( index, response => {
                response.id = answerResponseid[index];
                response.text_response = answer;
                response.options = [];
            } );
        }

        setPendingApiEmailInput( {
            currentQuestionID,
            userAssessmentResponseID,
            textResponse: answer
        } );
    };

    const handleEmailTypeNext = () => {
        if ( pendingApiEmailInput ) {
            const { currentQuestionID, userAssessmentResponseID, textResponse } = pendingApiEmailInput;

            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( [], textResponse, answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], textResponse );
            }
            setEmail( textResponse )
            setPendingApiEmailInput( null );
            localStorage.setItem( 'user_email', textResponse );
            //setEmail( textResponse )
            //console.log( email );
        }
        setCurrentDynamicStep( currentDynamicStep + 1 );
    }

    const handleBack = () => {
        if ( currentDynamicStep === 0 ) return null;
        setCurrentDynamicStep( currentDynamicStep - 1 );
    };

    const handleUploadFileChange = ( answer, currentDynamicStep, index ) => {

        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = answer;
        setTextAnswers( newTextAnswers );

        // Get the current question ID
        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = answer?.uploadedLink;
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = [];
            } );
        }

        if ( isPagination && answerResponseid[index] ) {
            updateMultipleQuestionResponse( index, response => {
                response.id = answerResponseid[index];
                response.text_response = answer?.uploadedLink;
                response.options = [];
            } );
        }
    };


    const handleNextUpload = () => {
        const currentQuestionID = questionId[currentDynamicStep];
        const textResponse = textAnswers[currentDynamicStep];
        const uploadedFileLink = textResponse?.uploadedLink;

        if ( uploadedFileLink ) {
            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( [], uploadedFileLink, answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], uploadedFileLink );
            }
            dispatch( updateAnswerIndex( currentDynamicStep ) );
        } else {
            // Dispatch resetAnswerStatus if selectedOptionId is empty
            dispatch( resetAnswerStatus( currentDynamicStep ) );
        }

        setCurrentDynamicStep( currentDynamicStep + 1 );
    };

    const handleVerificationType = ( answer, stepIndex ) => {
        if ( phoneNumber ) {
            setTextAnswers( phoneNumber );
        } else {
            let newTextAnswers = [...textAnswers];
            newTextAnswers[stepIndex] = answer;
            setTextAnswers( newTextAnswers );
        }

        const currentQuestionID = 4;

        setPendingApiVerification( {
            currentQuestionID,
            userAssessmentResponseID,
            numberTextResponse: answer
        } );
    };

    const handleVerificationTypeNext = () => {
        if ( pendingApiVerification ) {
            const { currentQuestionID, userAssessmentResponseID, numberTextResponse } = pendingApiVerification;
            createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], numberTextResponse );
            setEmail( numberTextResponse )
            setPhoneNumber( numberTextResponse )
            setPendingApiVerification( null );
        }
    };

    const handleStateAnswerChange = ( answer, currentDynamicStep, index ) => {
        // If the answer is not a string, convert it to a comma-separated string
        let processedAnswer = answer;
        if ( typeof answer !== 'string' ) {
            if ( Array.isArray( answer ) ) {
                // If the answer is an array (e.g., ["option", "option"]), join it with commas
                processedAnswer = answer.join( ', ' );
            } else if ( typeof answer === 'object' ) {
                // If the answer is an object, convert it to a comma-separated string of values
                processedAnswer = Object.values( answer ).join( ', ' );
            }
        }

        // Update the local state for text answers with the processed answer
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = answer;
        setTextAnswers( newTextAnswers );

        // Get the current question ID
        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = processedAnswer;
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = [];
            } );
        }

        if ( isPagination && answerResponseid[index] ) {
            updateMultipleQuestionResponse( index, response => {
                response.id = answerResponseid[index];
                response.text_response = processedAnswer;
                response.options = [];
            } );
        }

        // Populate pendingApiTextInput
        // setPendingStateQuestion( {
        //     currentQuestionID,
        //     userAssessmentResponseID,
        //     textResponse: processedAnswer
        // } );
    };

    const handleStateNext = () => {
        // Make API call using pendingApiTextInput if it exists
        const currentQuestionID = questionId[currentDynamicStep];
        const textResponse = textAnswers[currentDynamicStep];

        let processedAnswer = textResponse;

        if ( typeof textResponse !== 'string' ) {
            if ( Array.isArray( textResponse ) ) {
                // If the answer is an array (e.g., ["option", "option"]), join it with commas
                processedAnswer = textResponse.join( ', ' );
            } else if ( typeof textResponse === 'object' ) {
                // If the answer is an object, convert it to a comma-separated string of values
                processedAnswer = Object.values( textResponse ).join( ', ' );
            }
        }

        if ( processedAnswer !== "" ) {
            dispatch( updateAnswerIndex( currentDynamicStep ) );

            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( [], processedAnswer, answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], processedAnswer );
            }

        } else {
            dispatch( resetAnswerStatus( currentDynamicStep ) );
        }

        setCurrentDynamicStep( currentDynamicStep + 1 );

    };

    // const handleStateNext = () => {
    //     // Make API call using pendingApiTextInput if it exists
    //     if ( pendingStateQuestion ) {
    //         const { currentQuestionID, userAssessmentResponseID, textResponse } = pendingStateQuestion;

    //         if ( textResponse !== "" ) {
    //             dispatch( updateAnswerIndex( currentDynamicStep ) );
    //         } else {
    //             dispatch( resetAnswerStatus( currentDynamicStep ) );
    //         }

    //         if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
    //             updateUserQuestionResponse( [], textResponse, answerResponseid[currentDynamicStep] )
    //         } else {
    //             createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], textResponse );
    //         }
    //         setPendingStateQuestion( null );  // Clear pendingApiTextInput
    //     }
    //     setCurrentDynamicStep( currentDynamicStep + 1 );
    // };


    const handleOrderTypeQuestion = ( optionsOrder, currentDynamicStep, index, userAssessmentResponseID ) => {
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = optionsOrder;
        setTextAnswers( newTextAnswers );

        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = "";
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = optionsOrder;
            } );
        }

        if ( isPagination && answerResponseid[index] ) {
            updateMultipleQuestionResponse( index, response => {
                response.id = answerResponseid[index];
                response.text_response = "";
                response.options = optionsOrder;
            } );
        }

        // Populate pendingApiDataMultiChoice
        setPendingOrderType( {
            currentQuestionID,
            selectedOptionId: optionsOrder,
        } );
    }

    const handleOrderTypeNext = () => {
        if ( pendingOrderType ) {
            const { currentQuestionID, selectedOptionId } = pendingOrderType;

            if ( selectedOptionId.length > 0 ) {
                // Dispatch updateAnswerIndex if selectedOptionId is not empty
                dispatch( updateAnswerIndex( currentDynamicStep ) );
            } else {
                // Dispatch resetAnswerStatus if selectedOptionId is empty
                dispatch( resetAnswerStatus( currentDynamicStep ) );
            }

            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( selectedOptionId, '', answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, selectedOptionId );
            }

        }
        setCurrentDynamicStep( currentDynamicStep + 1 );
        setPendingOrderType( null );
    };

    const handleRangeTypeQuestion = ( range, currentDynamicStep, index ) => {
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = range;
        setTextAnswers( newTextAnswers );

        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = range;
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = [];
            } );
        }

        if ( isPagination && answerResponseid[index] ) {
            updateMultipleQuestionResponse( index, response => {
                response.id = answerResponseid[index];
                response.text_response = range;
                response.options = [];
            } );
        }

        setPendingApiRangeType( {
            currentQuestionID,
            userAssessmentResponseID,
            textResponse: range
        } );
    }

    const handleRangeTypeNext = () => {
        // Make API call using pendingApiTextInput if it exists
        if ( pendingApiRangeType ) {
            const { currentQuestionID, userAssessmentResponseID, textResponse } = pendingApiRangeType;

            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( [], textResponse, answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], textResponse );
            }
            setPendingApiTextInput( null );
        }
        setCurrentDynamicStep( currentDynamicStep + 1 );
    };

    const handleSkillSelector = ( selectedOptions, currentDynamicStep, index ) => {

        const selectedOptionsString = JSON.stringify( selectedOptions );

        // Update the local state for text answers
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = selectedOptions;
        setTextAnswers( newTextAnswers );

        // Get the current question ID
        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = selectedOptionsString;
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = [];
            } );
        }


        if ( isPagination && answerResponseid[index] ) {
            updateMultipleQuestionResponse( index, response => {
                response.id = answerResponseid[index];
                response.text_response = selectedOptionsString;
                response.options = [];
            } );
        }

        // Populate pendingApiTextInput
        setPendingStateQuestion( {
            currentQuestionID,
            userAssessmentResponseID,
            textResponse: selectedOptionsString
        } );
    }

    const handleIndustrySelector = ( selectedOptions, currentDynamicStep, index ) => {

        const selectedOptionsString = JSON.stringify( selectedOptions );

        // Update the local state for text answers
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = selectedOptions;
        setTextAnswers( newTextAnswers );

        // Get the current question ID
        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = selectedOptionsString;
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = [];
            } );
        }

        if ( isPagination && answerResponseid[index] ) {
            updateMultipleQuestionResponse( index, response => {
                response.id = answerResponseid[index];
                response.text_response = selectedOptionsString;
                response.options = [];
            } );
        }

        // Populate pendingApiTextInput
        setPendingStateQuestion( {
            currentQuestionID,
            userAssessmentResponseID,
            textResponse: selectedOptionsString
        } );
    }

    const handleAddressAnswerChange = ( answer, currentDynamicStep, index ) => {
        let newTextAnswers = [...textAnswers];
        newTextAnswers[currentDynamicStep] = answer;
        setTextAnswers( newTextAnswers );

        const currentQuestionID = dynamicQuestions[currentDynamicStep].id;
        const formattedAddress = `${ answer.houseNumber }, ${ answer.city }, ${ answer.state }, ${ answer.country }, ${ answer.pincode }`;

        if ( isPagination ) {
            updateQuestionResponse( index, response => {
                response.question_id = currentQuestionID;
                response.text_response = formattedAddress;
                response.time_taken = 0;
                response.user_assessment_response_id = userAssessmentResponseID;
                response.options = [];
            } );
        }

        if ( isPagination && answerResponseid[index] ) {
            updateMultipleQuestionResponse( index, response => {
                response.id = answerResponseid[index];
                response.text_response = formattedAddress;
                response.options = [];
            } );
        }
    };

    const handleAddressNext = () => {
        // Make API call using pendingApiTextInput if it exists
        const currentQuestionID = questionId[currentDynamicStep];
        const textResponse = textAnswers[currentDynamicStep];

        const formattedAddress = `${ textResponse.houseNumber }, ${ textResponse.city }, ${ textResponse.state }, ${ textResponse.country }, ${ textResponse.pincode }`;

        if ( formattedAddress !== "" && textResponse.pincode !== "" ) {
            // Dispatch updateAnswerIndex if selectedOptionId is not empty
            dispatch( updateAnswerIndex( currentDynamicStep ) );

            if ( Number.isInteger( answerResponseid[currentDynamicStep] ) ) {
                updateUserQuestionResponse( [], formattedAddress, answerResponseid[currentDynamicStep] )
            } else {
                createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], formattedAddress );
            }

        } else {
            // Dispatch resetAnswerStatus if selectedOptionId is empty
            dispatch( resetAnswerStatus( currentDynamicStep ) );
        }

        setCurrentDynamicStep( currentDynamicStep + 1 );
    };

    const totalSteps = dynamicQuestions.length;
    const completedProgress = Math.round( ( currentDynamicStep / dynamicQuestions.length ) * 100 );

    const renderQuestionComponent = ( currentDynamicStep, index ) => {
        const questionToRender = dynamicQuestions[currentDynamicStep];
        switch ( questionToRender?.input_type ) {
            case "single_choice_question":
                return (
                    <QuestionStep
                        question={ questionToRender }
                        selected={ textAnswers[currentDynamicStep] }
                        onSelect={ ( option ) => handleSingleChoiceSelect( option, currentDynamicStep, index ) }
                        onNext={ handleSingleChoiceNext }
                        onBack={ handleBack }
                        onClearAll={ handlesingleChoiceClearAll }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "multiple_choice_question":
                return (
                    <MultiChoiceStep
                        question={ questionToRender }
                        selectedOptionsFromParent={ textAnswers[currentDynamicStep] }
                        onSelect={ ( options ) => handleMultipleChoiceSelect( options, currentDynamicStep, index ) }
                        onNext={ handleMultiChoiceNext }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "drop_down_multiselect_question":
                return (
                    <DropDownStepMultipleSelect
                        question={ questionToRender }
                        selectedOptionsFromParent={
                            textAnswers[currentDynamicStep] === null ||
                                textAnswers[currentDynamicStep] === undefined ||
                                textAnswers[currentDynamicStep] === ""
                                ? []
                                : Array.isArray( textAnswers[currentDynamicStep] )
                                    ? textAnswers[currentDynamicStep]
                                    : [textAnswers[currentDynamicStep]]
                        }
                        onSelect={ ( selectedOptions ) => handleDropdownSelect( selectedOptions, currentDynamicStep, index ) }
                        onNext={ handleDropDownNext }
                        onBack={ handleBack }
                        onClearAll={ handlesingleChoiceClearAll }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "drop_down_singleselect_question":
                return (
                    <DropDownStepSingleSelect
                        question={ questionToRender }
                        selectedOptionsFromParent={ textAnswers[currentDynamicStep] }
                        onSelect={ ( selectedOptions ) => handleDropdownSelect( selectedOptions, currentDynamicStep, index ) }
                        onNext={ handleDropDownNext }
                        onBack={ handleBack }
                        onClearAll={ handlesingleChoiceClearAll }
                        courseName={ ( answer ) => handleCourseSelect( answer, currentDynamicStep, index ) }
                        selectedCourseName={ textAnswers[currentDynamicStep] }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "text_area_question":
                return (
                    <TextInputStep
                        question={ questionToRender }
                        answer={ textAnswers[currentDynamicStep] }
                        onAnswerChange={ ( answer ) => handleTextAnswerChange( answer, currentDynamicStep, index ) }
                        onNext={ handleTextNext }
                        onBack={ handleBack }
                        onClearAll={ handlesingleChoiceClearAll }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "address_input_type":
                return (
                    <AddressTypeQuestion
                        question={ questionToRender }
                        answer={ typeof textAnswers[currentDynamicStep] === 'object'
                            ? textAnswers[currentDynamicStep] // if it's already an object, keep it as it is
                            : ( () => {
                                const addressString = textAnswers[currentDynamicStep] || ""; // default to empty string if it's undefined
                                const parts = addressString.split( ',' ).map( part => part.trim() ); // split by commas and remove extra spaces
                                return {
                                    houseNumber: parts[0] || "",
                                    city: parts[1] || "",
                                    state: parts[2] || "",
                                    country: parts[3] || "",
                                    pincode: parts[4] || ""
                                };
                            } )() }
                        onAnswerChange={ ( answer ) => handleAddressAnswerChange( answer, currentDynamicStep, index ) }
                        onNext={ handleAddressNext }
                        onBack={ handleBack }
                        onClearAll={ handlesingleChoiceClearAll }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "number_input_question":
                return (
                    <NumberInputStep
                        question={ questionToRender }
                        answer={ textAnswers[currentDynamicStep] }
                        onAnswerChange={ ( answer ) => handleNumberTextAnswerChange( answer, currentDynamicStep, index ) }
                        onNext={ handleNumberTextNext }
                        onBack={ handleBack }
                        onClearAll={ handlesingleChoiceClearAll }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "upload_type_question":
                return (
                    <UploadFileInputType
                        question={ questionToRender }
                        selected={ textAnswers[currentDynamicStep] }
                        onSelect={ ( answer ) => handleUploadFileChange( answer, currentDynamicStep, index ) }
                        onNext={ handleNextUpload }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        onClearAll={ handlesingleChoiceClearAll }
                    />
                );
            case "email_type_question":
                return (
                    <EmailTypeStep
                        question={ questionToRender }
                        answer={ textAnswers[currentDynamicStep] }
                        onAnswerChange={ ( answer ) => handleEmailType( answer, currentDynamicStep, index ) }
                        onNext={ handleEmailTypeNext }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                )
            case "phone_number_input_question":
                return (
                    <PhoneNumberInputStep
                        question={ questionToRender }
                        answer={ textAnswers[currentDynamicStep] }
                        onAnswerChange={ ( answer ) => handleNumberTextAnswerChange( answer, currentDynamicStep, index ) }
                        onNext={ handleNumberTextNext }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                )
            case "states_selection_question":
                return (
                    <StateSelectionQuestion
                        question={ questionToRender }
                        answer={ textAnswers[currentDynamicStep] }
                        onAnswerChange={ ( answer ) => handleStateAnswerChange( answer, currentDynamicStep ) }
                        onNext={ handleStateNext }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                    />
                )
            case "name_input_question":
                return (
                    <NameInputStep
                        question={ questionToRender }
                        answer={ textAnswers[currentDynamicStep] }
                        onAnswerChange={ ( answer ) => handleNameAnswerChange( answer, currentDynamicStep, index ) }
                        onNext={ handleNameNext }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "date_input_type":
                return (
                    <DobInputType
                        question={ questionToRender }
                        answer={ textAnswers[currentDynamicStep] }
                        onAnswerChange={ ( answer ) => handleTextAnswerChange( answer, currentDynamicStep, index ) }
                        onNext={ handleTextNext }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "number_and_text_input_type":
                return (
                    <FlexibleInputStep
                        question={ questionToRender }
                        answer={ textAnswers[currentDynamicStep] }
                        onAnswerChange={ ( answer ) => handleTextAnswerChange( answer, currentDynamicStep, index ) }
                        onNext={ handleTextNext }
                        onBack={ handleBack }
                        onClearAll={ handlesingleChoiceClearAll }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "indian_state_selection_type":
                return (
                    <IndianStateSelectionQuestion
                        question={ questionToRender }
                        answer={ textAnswers[currentDynamicStep] }
                        onAnswerChange={ ( answer, ) => handleStateAnswerChange( answer, currentDynamicStep, index ) }
                        onNext={ handleStateNext }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );

            case "subject_selection_question":
                return (
                    <SubjectSelectionQuestion
                        question={ questionToRender }
                        answer={ textAnswers[currentDynamicStep] &&
                            Array.isArray( textAnswers[currentDynamicStep] )
                            ? textAnswers[currentDynamicStep]
                            : textAnswers[currentDynamicStep] && textAnswers[currentDynamicStep]
                                .split( ',' )
                                .map( ( item ) => item.trim() )
                                .filter( ( item ) => item )

                        }
                        onAnswerChange={ ( answer, ) => handleStateAnswerChange( answer, currentDynamicStep, index ) }
                        onNext={ handleStateNext }
                        onBack={ handleBack }
                        onClearAll={ handlesingleChoiceClearAll }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "indian_city_selection_type":
                return (
                    <IndianCitiesSelectionQuestion
                        question={ questionToRender }
                        answer={ textAnswers[currentDynamicStep] }
                        onAnswerChange={ ( answer, ) => handleStateAnswerChange( answer, currentDynamicStep, index ) }
                        onNext={ handleStateNext }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "nudge":
                return (
                    <AdvertiseMentModal
                        onNext={ () => {
                            setShowProgressBar( true )
                            setCurrentDynamicStep( currentDynamicStep + 1 )
                        } }
                        resetAdvertisement={ resetAdvertisement }
                        nudge={ dynamicQuestions[currentDynamicStep]?.nudge }
                        onLoad={ () => setShowProgressBar( false ) }
                        onBack={ () => {
                            setShowProgressBar( true );
                            setCurrentDynamicStep( currentDynamicStep - 1 )
                        } }
                        isPagination={ isPagination }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "ranking_input_type":
                return (
                    <OrderTypeQuestion
                        question={ questionToRender }
                        selectedOptionsFromParent={ textAnswers[currentDynamicStep] }
                        onSelect={ ( optionsOrder ) => handleOrderTypeQuestion( optionsOrder, currentDynamicStep, index, userAssessmentResponseID ) }
                        onNext={ handleOrderTypeNext }
                        onBack={ handleBack }
                        onClearAll={ handlesingleChoiceClearAll }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "range_type_question":
                return (
                    <RangeTypeQuestion
                        question={ questionToRender }
                        answer={ textAnswers[currentDynamicStep] }
                        onAnswerChange={ ( range ) => handleRangeTypeQuestion( range, currentDynamicStep, index ) }
                        onNext={ handleRangeTypeNext }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "industry_selector":
                return (
                    <IndustrySelector
                        question={ questionToRender }
                        selectedOptionsFromParent={ textAnswers[currentDynamicStep] }
                        onSelect={ ( selectedOptions ) => handleIndustrySelector( selectedOptions, currentDynamicStep, index ) }
                        onNext={ handleStateNext }
                        onBack={ handleBack }
                        onClearAll={ handlesingleChoiceClearAll }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            case "skill_selector":
                return (
                    <SkillSelector
                        question={ questionToRender }
                        selectedOptionsFromParent={ textAnswers[currentDynamicStep] }
                        onSelect={ ( selectedOptions ) => handleSkillSelector( selectedOptions, currentDynamicStep, index ) }
                        onNext={ handleStateNext }
                        onBack={ handleBack }
                        isPagination={ isPagination }
                        isQuestionNavigation={ isQuestionNavigation }
                        isValidation={ ( value ) => {
                            setValidations( ( prevValidations ) => {
                                const newValidations = [...prevValidations];
                                newValidations[index] = value;
                                return newValidations;
                            } );
                        } }
                    />
                );
            default:
                return (
                    <TestSubmissionPage />
                );
        }
    };

    const renderQuestionBatch = ( startIndex ) => {
        return dynamicQuestions
            .slice( startIndex, startIndex + batchSize )
            .map( ( question, index ) => {
                return renderQuestionComponent( startIndex + index, index );
            } );
    };

    const handleResetValidations = ( size = batchSize ) => {
        setValidations( Array( size ).fill( {} ) ); // Initialize validations based on the actual size passed
    };


    useEffect( () => {
        const allTrue = validations.length > 0 && validations.every( value => value === true );
        setIsAllAnswersValid( allTrue );
    }, [validations] );


    const handleNextBatch = ( startIndex ) => {
        const questionCount = dynamicQuestions.length - startIndex;
        const currentBatchSize = Math.min( questionCount, batchSize );

        if ( isAllAnswersValid ) {
            if ( backUpdateQuestionsResponse ) {
                updateMultipleQuestionResponseCall();
            } else {
                multipleQuestionResponses();
            }
            setCurrentDynamicStep( startIndex );

            containerRef.current.scrollTo( 0, 0 );
            window.scrollTo( {
                top: 0,
                behavior: 'smooth',
            } );

            if ( startIndex >= dynamicQuestions.length ) {
                return;
            } else {
                handleResetValidations( currentBatchSize ); // Only reset with a positive size
                setBackUpdateQuestionsResponse( false );
            }
        } else {
            notification.error( {
                message: 'Please attempt all the questions',
            } );
        }
    };

    const handlePreviousBatch = ( startIndex ) => {
        if ( currentDynamicStep === 0 ) return null;
        setBackUpdateQuestionsResponse( true )
        setCurrentDynamicStep( startIndex );
        containerRef.current.scrollTo( 0, 0 );
    };

    const renderQuestions = ( startIndex ) => {
        return (
            <>
                { isPagination ? (
                    <>
                        { renderQuestionBatch( startIndex ) }

                        <div className="flex flex-row gap-2 justify-end pb-8">
                            <button
                                className="next-button"
                                onClick={ () => handleNextBatch( startIndex + batchSize ) }
                                style={ {
                                    backgroundColor: isAllAnswersValid ? clientConfig.primary_color : `${ clientConfig.primary_color }80`,
                                    cursor: isAllAnswersValid ? 'pointer' : 'not-allowed'
                                } }
                            >
                                Next
                            </button>

                            <button
                                className="back-button"
                                onClick={ () => handlePreviousBatch( startIndex - batchSize ) }
                            >
                                Back
                            </button>
                        </div>
                    </>
                ) : (
                    renderQuestionComponent( startIndex )
                ) }
            </>
        );
    };

    //const handleFinishAttempt = () => {
    //  if ( isRequiredQuestionsAttempted ) {
    //    updateResponse( { id: userAssessmentResponseID, is_completed: 3 } )
    //    dispatch( setIsLoadingScreen( true ) );
    //    localStorage.setItem( 'isLoadingScreen', true )
    //  } else {
    //    notification.error( {
    //      message: 'Unable to submit!',
    //      description: 'Please attempt all the required questions to submit the test.',
    //    } );
    //  }
    //}

    //const handleFinishAttempt = () => {
    //  updateResponse( { id: userAssessmentResponseID, is_completed: 3 } )
    //  dispatch( setIsLoadingScreen( true ) );
    //  localStorage.setItem( 'isLoadingScreen', true )
    //  setIsModalOpen( false );
    //}

    useEffect( () => {
        if ( currentDynamicStep > 0 )
            if ( data?.questions && currentDynamicStep + 1 > dynamicQuestions.length ) {
                setIsModalOpen( true );
            } else {
                setIsModalOpen( false );
            }
    }, [currentDynamicStep] )

    const handleOk = () => {
        if ( isQuestionNavigation ) {
            if ( isRequiredQuestionsAttempted ) {
                updateResponse( { id: userAssessmentResponseID, is_completed: 3, clientName: client_name } )
                // setCurrentComponent( currentComponent + 1 );
                dispatch( nextFlow() );
                dispatch( resetState() );
                if ( test_series_id ) {
                    navigate( `/${ client_name }/thank_you/${ currentQueryParams }` )
                } else {
                    navigate( `/${ client_name }/${ test_type }/${ testId }/flow/` )
                }
                setIsModalOpen( false );
            } else {
                notification.error( {
                    message: 'Unable to submit!',
                    description: 'Please attempt all the questions to submit the test.',
                } );
            }
        } else {
            const user_email = localStorage.getItem( 'user_email' ) || "user_email";
            const userAddress = localStorage.getItem( 'userAddress' ) || "user_address";
            const contactPersonPhone = localStorage.getItem( 'contact_person_phone' ) || "contact_person_phone";
            const contactPersonName = localStorage.getItem( 'contact_person_name' ) || "contact_person_name";
            const schoolName = localStorage.getItem( 'userSchoolName' ) || "school_name";

            const studentName = localStorage.getItem( 'studentName' ) || localStorage.getItem( 'user_name' );
            const studentGrade = localStorage.getItem( 'studentGrade' ) || "";
            const studentEmail = localStorage.getItem( 'studentEmail' ) || localStorage.getItem( 'user_email' );
            const responseId = localStorage.getItem( 'userAssessmentResponseID' ) || "1234";
            const studentDateOfBirth = localStorage.getItem( 'studentDateOfBirth' ) || "1970-01-01";
            const examinationMode = localStorage.getItem( 'examination_mode' ) || "online";

            const studentPh = localStorage.getItem( 'phone_number' ) || studentPhone || "";

            const userId = localStorage.getItem( 'student_roll_number' );

            // if ( !userId ) {
            //     updateResponse( { id: userAssessmentResponseID, is_completed: 3, clientName: client_name } )
            //     if ( school_registration_route ) {
            //         createSchoolCode( schoolName, userAddress, contactPersonName, contactPersonPhone, user_email, examinationMode, responseId, client_name );
            //     } else if ( student_registration_route ) {
            //         createStudentCode( studentName, studentGrade, user_email, client_name, responseId, studentDateOfBirth, form_config_data?.client_id, studentPh )
            //     } else {
            //         return;
            //     }
            //     setIsModalOpen( false );
            // } else {
            //     updateResponse( { id: userAssessmentResponseID, is_completed: 3, clientName: client_name } )
            //     // setCurrentComponent( currentComponent + 1 );
            //     dispatch( nextFlow() );
            //     dispatch( resetState() );
            //     navigate( `/${ client_name }/${ test_type }/flow/` )
            //     setIsModalOpen( false );
            // }
            updateResponse( { id: userAssessmentResponseID, is_completed: 3, clientName: client_name } )
            // setCurrentComponent( currentComponent + 1 );
            // dispatch( nextFlow() );
            // dispatch( resetState() );
            // navigate( `/${ client_name }/${ test_type }/flow/` )
            setIsModalOpen( false );
        }
    };

    useEffect( () => {
        if ( updateUserResponseData && updateUserResponseData?.is_completed === 3 ) {
            dispatch( nextFlow() );
            dispatch( resetState() );
            navigate( `/${ client_name }/${ test_type }/${ testId }/flow/` )
        }
    }, [updateUserResponseData] );

    useEffect( () => {
        if ( createSchoolResponse && !schoolError ) {
            localStorage.clear();
        } else if ( createStudentResponse && !studentError ) {
            const studentRollNumber = createStudentResponse?.student_code;
            localStorage.setItem( 'student_roll_number', studentRollNumber );
            if ( viewReport ) {
                Object.keys( localStorage ).forEach( ( key ) => {
                    if ( key !== 'phone_number' && key !== 'student_roll_number' && key !== 'studentName' && key !== 'userAssessmentResponseID' && key !== 'user_email' && key !== 'product_code' && key !== 'user_name' && key !== 'clientId' ) {
                        localStorage.removeItem( key );
                    }
                } );
                setCurrentComponent( currentComponent + 1 );
            } else {
                localStorage.clear();
            }
        }
    }, [createSchoolResponse, createStudentResponse] )

    const handleCancel = () => {
        setCurrentDynamicStep( currentDynamicStep - 1 );
        setIsModalOpen( false );
    };

    const isWide = useMedia( '(max-width: 1050px)' );

    const userEmailLocalStorage = localStorage.getItem( 'user_email' ) || userEmail || "";
    const userNameLocalStorage = localStorage.getItem( 'studentName' ) || userName || "";

    const showInstructionsModal = () => {
        setInstructionModalTest( true )
    };

    const closeInstructionsModal = () => {
        setInstructionModalTest( false );
    }

    const handleProhibitAction = () => {
        updateResponse( { id: userAssessmentResponseID, is_completed: 3, clientName: client_name } )
        dispatch( nextFlow() );
        dispatch( resetState() );
        if ( test_series_id ) {
            navigate( `/${ client_name }/thank_you/${ currentQueryParams }` )
        } else {
            navigate( `/${ client_name }/${ test_type }/${ testId }/flow/` )
        }
    }

    useEffect( () => {
        if ( isLeftSidebarVisible ) return;
        if ( formTimer === 'per_question_timer' ) {
            dispatch( setIsRequiredQuestionsAttempted( true ) );
        } else {
            // Filter out the required questions
            const requiredQuestions = dynamicQuestions
                .map( ( question, index ) => ( question.question_optional === 'required' ? index : null ) ) // Get index of required questions
                .filter( index => index !== null ); // Remove null values from optional questions

            // Check if all required questions have been attempted (i.e., true in textAnswers)
            const allRequiredAttempted = requiredQuestions?.every( index => textAnswersReduxState[index] === true );
            // Dispatch the result based on whether all required questions were attempted
            dispatch( setIsRequiredQuestionsAttempted( allRequiredAttempted ) );
        }
    }, [textAnswersReduxState, dynamicQuestions, formTimer, currentDynamicStep] );

    return (
        <div className="flex justify-center items-center">
            <>
                <Helmet>
                    <link rel="icon" href={ clientConfig.favicon } />
                </Helmet>
                <Modal
                    title="Notice"
                    open={ isClientActiveModal }
                    centered={ true }
                    closable={ false }
                    maskClosable={ false }
                    footer={ null }
                >
                    <p>Service temporarily blocked. Please contact the owner to restore access. Sorry for the inconvenience</p>
                </Modal>
                {/*<Modal
          title="Authentication Failed"
          visible={ !isUserRegistered }
          centered
          closable={ false }
          maskClosable={ false }
          footer={ [
            <button className="border border-2 text-white font-bold rounded-lg px-4 py-2" onClick={ handleSignUp } style={ { backgroundColor: clientConfig?.primary_color } }>
              Sign Up
            </button>
          ] }
        >
          <p className="text-sm font-normal">You are not registered. Please sign up by clicking on the button below.</p>
        </Modal>*/}
                <AuthModal isUserRegistered={ isUserRegistered } />
                <Modal
                    title="Note"
                    open={ isTestAlreadyGiven }
                    centered
                    closable={ false }
                    maskClosable={ false }
                    footer={ [
                        <button className="border border-2 text-white font-bold rounded-lg px-4 py-2" onClick={ handleProhibitAction } style={ { backgroundColor: clientConfig?.primary_color } }>
                            Let's Move On
                        </button>
                    ] }
                >
                    <p className="text-sm font-normal">Oops! You've already given the test.</p>
                </Modal>
                <Modal
                    title="Note"
                    open={ refreshTest }
                    centered
                    closable={ false }
                    maskClosable={ false }
                    footer={ [
                        <button className="border border-2 text-white font-bold rounded-lg px-4 py-2" onClick={ handleProhibitAction } style={ { backgroundColor: clientConfig?.primary_color } }>
                            Let's Move On
                        </button>
                    ] }
                >
                    <p className="text-sm font-normal">Oops! This action is not allowed. Hence, your assessment has been auto submitted.</p>
                </Modal>
                {
                    ( creatingSchool || creatingStudent || updateUserResponseLoading || studentDataLoading ) &&
                    <div className="overlay">
                        <Spin size="large" />
                    </div>
                }
                { ( isLoading || formConfigLoading ) ?
                    <div className="flex items-center justify-center h-screen">
                        <Lottie animationData={ animationData } loop autoplay style={ { width: '250px', height: '250px' } } />
                    </div>
                    :
                    <>
                        <Modal
                            title="Are you sure you want to submit?"
                            open={ isModalOpen }
                            onOk={ handleOk }
                            onCancel={ handleCancel }
                            cancelText="Go Back"
                            okText="Submit"
                            centered={ true }
                            okButtonProps={ { style: { backgroundColor: '#3b82f6' } } }
                        >
                            <p>(Once submitted, answers cannot be altered)</p>
                        </Modal>
                        <InstructionsModalTest instructions={ instructions } isOpen={ instructionModalTest } onOk={ closeInstructionsModal } />
                        <div className="container-fluid">
                            <div className="row row-height">
                                {
                                    isLeftSidebarVisible && clientConfig && formConfig && !isQuestionNavigation ?
                                        <LeftSideBar
                                            data={ data }
                                            formConfig={ form_config_data }
                                            currentComponentNext={ () => setCurrentComponent( currentComponent + 1 ) }
                                            currentDynamicStepBack={ () => {
                                                if ( currentDynamicStep !== 0 )
                                                    setCurrentDynamicStep( currentDynamicStep - 1 )
                                            } }
                                            currentDynamicStep={ currentDynamicStep }
                                            timeCompleted={ () => setCurrentDynamicStep( currentDynamicStep + 1 ) }
                                            testTimeInSeconds={ testTimeInSeconds }
                                        />
                                        :
                                        ""
                                }
                                {
                                    isLeftSidebarVisible && clientConfig && formConfig && isQuestionNavigation && isWide ?
                                        <LeftSideBar
                                            data={ data }
                                            formConfig={ form_config_data }
                                            currentComponentNext={ () => setCurrentComponent( currentComponent + 1 ) }
                                            currentDynamicStepBack={ () => {
                                                if ( currentDynamicStep !== 0 )
                                                    setCurrentDynamicStep( currentDynamicStep - 1 )
                                            } }
                                            currentDynamicStep={ currentDynamicStep }
                                            timeCompleted={ () => setCurrentDynamicStep( currentDynamicStep + 1 ) }
                                            testTimeInSeconds={ testTimeInSeconds }
                                        /> : ""
                                }
                                { isConsentForm && isClientActive && isUserRegistered && !isTestAlreadyGiven && !refreshTest && (
                                    <TestTermsAndConditions onSubmit={ handleProceedToQuiz } link={ terms_and_conditions } />
                                ) }
                                {/*<div className={ `${ isLeftSidebarVisible ? ( isQuestionNavigation ? 'col-xl-9 col-lg-9 h-full overflow-hidden' : 'col-xl-8 col-lg-8 content-right' ) (isPagination ? "overflow-y-auto" : ""): 'flex justify-center p-10' }` } id="start">*/ }
                                <div ref={ containerRef } className={ `${ isLeftSidebarVisible ? ( isQuestionNavigation ? 'col-xl-9 col-lg-9 h-full' : 'col-xl-8 col-lg-8 content-right' ) : 'flex justify-center p-10' } ${ isLeftSidebarVisible && isPagination ? 'overflow-y-auto' : '' }` } id="start" >
                                    <>
                                        <div className={ `${ showProgressBar ? "justify-start " : "justify-center " }${ isQuestionNavigation ? "w-full h-full flex flex-col" : "flex flex-col w-full xl:w-3/4 lg:w-3/4 sm:w-full sm:gap-10 lg:gap-0 sm:mt-[1rem]" }` }>
                                            <div className={ isQuestionNavigation ? 'flex flex-col p-2' : 'p-4 pt-0 sm:p-4 h-full' } style={ { height: '-webkit-fill-available' } }>

                                                {
                                                    isQuestionNavigation &&
                                                    <>
                                                        <div className="w-full px-4 pt-2 pb-4 flex lg:flex-row lg:items-center lg:justify-between sm:flex-col sm:items-start sm:gap-[1rem] font-poppins">
                                                            <div className="w-fit py-2 px-2 rounded-md" style={ { backgroundColor: clientConfig.primary_color } }>
                                                                <p className="text-white lg:text-lg sm:text-sm font-semibold">{ form_name }</p>
                                                            </div>
                                                            <div className="flex items-center cursor-pointer w-fit py-2 px-2 rounded-md border-2" onClick={ showInstructionsModal }>
                                                                <InfoCircleFilled style={ { marginRight: '8px', fontSize: '20px', color: clientConfig.primary_color } } />
                                                                <p className="font-semibold lg:text-lg sm:text-sm">Instructions</p>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    !isQuestionNavigation && isClientActive &&
                                                    <>
                                                        <ProgressBar
                                                            completed={ completedProgress }
                                                            maxCompleted={ 100 }
                                                            bgColor={ clientConfig.primary_color }
                                                            isLabelVisible={ false }
                                                            height={ 15 }
                                                            style={ {
                                                                display: 'content',
                                                                alignItems: 'initial',
                                                            } }
                                                        />
                                                        <p className="text-lg font-light mt-2">
                                                            <span style={ { color: clientConfig.primary_color } }>{ completedProgress }%</span> out of <span style={ { color: clientConfig.primary_color } }>100%</span> completed.
                                                        </p>
                                                    </>
                                                }

                                                <div className={ `h-full overflow-hidden ${ isQuestionNavigation ? 'mt-0 px-4 pt-1 flex-1 h-full' : 'mt-8' }` }>
                                                    {
                                                        renderQuestions( currentDynamicStep )
                                                    }
                                                    {/* {
                                                        currentFlowName === "phone_verification" &&
                                                        <MobileVerification
                                                            onAnswerChange={ ( currentQuestionText, answer ) => handleVerificationType( currentQuestionText, answer, currentDynamicStep ) }
                                                            onNext={ handleVerificationTypeNext }
                                                            phoneNumber={ phoneNumber }
                                                            currentComponent={ () => setCurrentComponent( currentComponent + 1 ) }
                                                        />
                                                    } */}
                                                    {/* {
                                                        currentFlowName === "email_verification" &&
                                                        <EmailVerification
                                                            onAnswerChange={ ( currentQuestionText, answer ) => handleVerificationType( currentQuestionText, answer, currentDynamicStep ) }
                                                            onNext={ handleVerificationTypeNext }
                                                            userEmail={ email }
                                                            currentComponent={ () => setCurrentComponent( currentComponent + 1 ) }
                                                        />
                                                    }
                                                    {
                                                        currentFlowName === "loading_screen" &&
                                                        <Review data={ data } />
                                                    }
                                                    {
                                                        currentFlowName === "sign_up_view" &&
                                                        navigate( `/${ client_name }/${ test_type }/signup`, { replace: true } )
                                                    }
                                                    {
                                                        currentFlowName === "coupon_code_verification" &&
                                                        navigate( `/${ client_name }/verification/coupon_code`, { replace: true } )
                                                    }
                                                    {
                                                        currentFlowName === "instructions_view" &&
                                                        navigate( `/${ client_name }/test/${ student_grade_local_storage }/instructions`, { replace: true } )
                                                    }
                                                    {
                                                        currentFlowName === "test_selection_view" &&
                                                        navigate( `/${ client_name }/test/selection/?user_email=${ userEmailLocalStorage }&user_name=${ userNameLocalStorage }&user_id=${ createStudentResponse?.student_code }`, { replace: true } )
                                                    }
                                                    {
                                                        currentFlowName === "thank_you_page_view" &&
                                                        navigate( `/${ client_name }/thank_you/?user_email=${ userEmailLocalStorage }&user_name=${ userNameLocalStorage }&user_id=${ studentRollNumber }`, { replace: true } )
                                                    } */}

                                                    {
                                                        isWide ?
                                                            <button
                                                                className="fixed bottom-4 right-4 text-white p-2 rounded-full"
                                                                onClick={ () => {
                                                                    window.scrollTo( {
                                                                        top: document.documentElement.scrollHeight,
                                                                        behavior: 'smooth',
                                                                    } );
                                                                } }
                                                                style={ { backgroundColor: clientConfig.secondary_color } }
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="h-6 w-6"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke="currentColor"
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth={ 2 }
                                                                        d="M19 14l-7 7m0 0l-7-7m7 7V3"
                                                                    />
                                                                </svg>
                                                            </button>
                                                            :
                                                            <></>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </>
                                </div>
                                {
                                    isLeftSidebarVisible && clientConfig && formConfig && isQuestionNavigation && !isWide ?
                                        <LeftSideBar
                                            data={ data }
                                            formConfig={ form_config_data }
                                            currentComponentNext={ () => setCurrentComponent( currentComponent + 1 ) }
                                            currentDynamicStepBack={ () => {
                                                if ( currentDynamicStep !== 0 )
                                                    setCurrentDynamicStep( currentDynamicStep - 1 )
                                            } }
                                            currentDynamicStep={ currentDynamicStep }
                                            timeCompleted={ () => setCurrentDynamicStep( currentDynamicStep + 1 ) }
                                            testTimeInSeconds={ testTimeInSeconds }
                                        /> : ""
                                }
                            </div>
                        </div>
                    </>
                }
            </>
        </div>
    );
};

export default MultiStepForm;
