import { useState } from 'react';
import axios from 'axios';
import {
    BASE_URL, UPDATE_MULTIPLE_QUESTION_RESPONSE
} from '../utils/constants/constants';

const useUpdateMultipleQuestionResponse = ( batchSize ) => {
    const initialQuestionResponse = {
        "id": 0,
        "text_response": "",
        "options": [],
    };

    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );
    const [questionResponses, setQuestionResponses] = useState( [] );

    const API_URL = `${ BASE_URL }${ UPDATE_MULTIPLE_QUESTION_RESPONSE }`;

    const updateMultipleQuestionResponse = ( index, updateFunction ) => {
        setQuestionResponses( prevResponses => {
            const updatedResponse = { ...prevResponses[index] };
            updateFunction( updatedResponse );
            const updatedResponses = [...prevResponses];
            updatedResponses[index] = updatedResponse;
            return updatedResponses;
        } );
    };

    const resetQuestionResponses = () => {
        setQuestionResponses( [] );
    };

    const updateMultipleQuestionResponseCall = async () => {
        setIsLoading( true );
        try {

            const filteredResponses = questionResponses.filter( response => ( response !== null && response !== undefined ) );

            const res = await axios.put( API_URL, filteredResponses );
            setResponse( res.data );
            setError( null );
            resetQuestionResponses(); // Reset questionResponses on success
        } catch ( err ) {
            setError( err );
        } finally {
            setIsLoading( false );
        }
    };

    return { response, error, isLoading, updateMultipleQuestionResponse, updateMultipleQuestionResponseCall };
};

export default useUpdateMultipleQuestionResponse;