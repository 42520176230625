import React, { useEffect, useState } from 'react';
import LeftSideBar from '../LeftSideBar';
import { useParams, useNavigate } from 'react-router-dom';
import { dynamicData } from '../../helpers/data';
import useVerifyCouponCode from '../../api/VerifyCoupon';
import {
    EditOutlined,
    CheckCircleOutlined
} from '@ant-design/icons';
import { notification } from "antd";


const CouponCodeVerification = () => {
    const { client_name, grade } = useParams();
    const [couponCode, setCouponCode] = useState( '' );
    const [email, setEmail] = useState( '' );
    const [isMailEditable, setIsMailEditable] = useState( false );
    const [isCouponCodeEditable, setIsCouponCodeEditable] = useState( false );

    const navigate = useNavigate();

    const { response, isLoading, error, verifyCouponCode } = useVerifyCouponCode();
    const student_grade_local_storage = localStorage.getItem( 'student_grade' );
    const student_email = localStorage.getItem( 'student_email' );

    useEffect( () => {
        setEmail( student_email );
    }, [] )

    const handleEditEmail = () => {
        setIsCouponCodeEditable( true );
    }

    const handleNoEditEmail = () => {
        setIsCouponCodeEditable( false );
    }

    const handleVerify = () => {
        verifyCouponCode( couponCode, email );
    }

    useEffect( () => {
        if ( response ) {
            if ( response && response.data && response.data === true ) {
                notification.success( {
                    message: 'Coupon Code verified',
                    description: 'Your Coupon has been successfully verified.'
                } );
                navigate( `/${ client_name }/test/${ student_grade_local_storage }/instructions` )
            } else {
                notification.error( {
                    message: 'Incorrect Coupon Code',
                    description: 'Please type the correct coupon code'
                } );
            }
        } else {
        }
    }, [response] )

    return (
        <div className="container-fluid">
            <div className="row row-height">
                <LeftSideBar />
                <div className="col-xl-8 col-lg-8 content-right" id="start">
                    <>
                        <div className='w-[700px]'>
                            <div className="flex flex-col">
                                <p className='text-2xl text-bold text-black '>Your Email</p>
                                <div>
                                    <input
                                        type="text"
                                        inputMode="numeric"
                                        value={ email }
                                        disabled={ !isMailEditable }
                                        className={ `w-1/2 rounded ${ !isMailEditable ? "bg-gray-200" : "bg-white" } p-3 border-2 focus:border-blue-500` }
                                        style={ { resize: "none" } }
                                    />
                                </div>
                                <div className='mt-10'>
                                    <p className='text-2xl text-bold text-black '>Enter the Coupon Code Provided</p>
                                    <input
                                        type="text"
                                        inputMode="numeric"
                                        value={ couponCode }
                                        onChange={ ( e ) => setCouponCode( e.target.value ) }
                                        disabled={ !isCouponCodeEditable }
                                        className={ `w-1/2 rounded ${ !isCouponCodeEditable ? "bg-gray-200" : "bg-white" } p-3 border-2 focus:border-blue-500` }
                                        style={ { resize: "none" } }
                                    />
                                    { !isCouponCodeEditable ?
                                        <EditOutlined
                                            onClick={ handleEditEmail }
                                            style={ { fontSize: '25px' } }
                                            className="ml-4"
                                        />
                                        :
                                        <CheckCircleOutlined
                                            onClick={ handleNoEditEmail }
                                            style={ { fontSize: '25px' } }
                                            className="ml-4"
                                        />
                                    }
                                </div>
                                <button
                                    onClick={ handleVerify }
                                    className="w-1/4 px-4 py-2 mt-10 rounded-md text-white bg-blue-500"
                                >
                                    Verify
                                </button>
                            </div>
                        </div>
                    </>
                </div>
            </div>
        </div>
    );
};

export default CouponCodeVerification;