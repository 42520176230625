import { useState } from "react";
import axios from "axios";
import { BASE_URL, FETCH_FORMS_BY_USER_ID } from "../utils/constants/constants";

const useFetchFormsByUserId = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );

    const FETCH_DATA = `${ BASE_URL }${ FETCH_FORMS_BY_USER_ID }`

    const FetchUserForms = async ( client_name, user_id, form_type, testSeriesId ) => {

        setIsLoading( true );

        try {
            const res = await axios.get( `${ FETCH_DATA }?client_code=${ client_name }&user_id=${ user_id }&form_type=${ form_type }&test_series=${ testSeriesId }` );
            setResponse( res.data );
            setError( null );
        } catch ( err ) {
            setError( err );
            console.log( err );
        } finally {
            setIsLoading( false );
        }
    };

    return { response, error, isLoading, FetchUserForms };
};

export default useFetchFormsByUserId;