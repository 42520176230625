import React, { useState, useEffect } from "react";
import { Input, Spin, notification } from "antd";
import {
    EditTwoTone,
    CheckCircleOutlined,
}
    from '@ant-design/icons';
import useVerifyOTPEmail from "../../api/VerifyOtpEmail";
import useRequestOTPEmail from "../../api/RequestOtpEmail";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { nextFlow } from "../../redux/student/studentSlice";
import useUpdateVerifyStudents from "../../api/useUpdateVerifyStudent";
import LeftSideBar from "../LeftSideBar";

const EmailVerification = () => {
    const [isEmailValid, setIsEmailValid] = useState( false );
    const [isOTPBoxVisible, setIsOTPBoxVisible] = useState( false );
    const [isEditable, setIsEditable] = useState( false );
    const [email, setEmail] = useState( "" );
    const [otpValues, setOtpValues] = useState( ["", "", "", ""] );
    const [isCheckIcon, setIsCheckIcon] = useState( false );
    const { client_name, test_type } = useParams();
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const location = useLocation();
    const { test_series_id } = location.state || {};

    const [queryParams, setQueryParams] = useState( '' );

    const [isSendOTPClickable, setIsSendOTPClickable] = useState( true );
    const [isVerifyOTPClickable, setIsVerifyOTPClickable] = useState( false );
    const { response: updatedResponse, isLoading: updatingStudent, updateVerifyStudents } = useUpdateVerifyStudents();
    const studentRollNumber = localStorage.getItem( 'student_roll_number' );
    const [resendClickable, setResendClickable] = useState( false );
    const [timer, setTimer] = useState( 30 );

    const userEmail = localStorage.getItem( 'user_email' );

    useEffect( () => {
        setEmail( userEmail );
        setIsEmailValid( /^[a-zA-Z0-9]+([\.-]?[a-zA-Z0-9]+){0,5}@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]+$/.test( userEmail ) );
    }, [userEmail] );

    useEffect( () => {
        const currentParams = new URLSearchParams( window.location.search );
        setQueryParams( currentParams.toString() );
    }, [] );

    const { emailData, isRequestingOTP, requestOTP } = useRequestOTPEmail();
    const { data, isLoading, error, verifyOTP } = useVerifyOTPEmail();

    useEffect( () => {
        if ( !isLoading ) {
            if ( data && !error ) {
                updateVerifyStudents( studentRollNumber, true, false )
            }
        }
    }, [data] );

    useEffect( () => {
        if ( updatedResponse ) {
            notification.success( {
                message: 'OTP Verified',
                description: 'Your OTP has been successfully verified.'
            } );
            if ( test_series_id ) {
                navigate( `/${ client_name }/${ test_type }/overall_instructions/?${ queryParams }`, { replace: true, state: { test_series_id: test_series_id } } );
            } else {
                dispatch( nextFlow() );
                navigate( `/${ client_name }/${ test_type }/flow/?${ queryParams }`, { replace: true } );
            }
        }
    }, [updatedResponse] )

    const handleEditEmail = () => {
        setIsEditable( true );
        setIsCheckIcon( true );
        setIsSendOTPClickable( false );
        setIsOTPBoxVisible( false );
        setIsVerifyOTPClickable( false );
    };

    const handleCheckEmail = () => {
        if ( isEmailValid ) {
            setIsEditable( false );
            setIsCheckIcon( false );
            setIsSendOTPClickable( true );
        }
    };

    const handleSendOTP = () => {
        setIsOTPBoxVisible( true );
        setIsSendOTPClickable( false );
        setIsVerifyOTPClickable( true );
        requestOTP( email, client_name );
    };

    const handleEmailChange = ( value ) => {
        setEmail( value );
        setIsEmailValid( /^[a-zA-Z0-9]+([\.-]?[a-zA-Z0-9]+){0,5}@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]+$/.test( value ) );
    };

    const handleOTPChange = ( e, index ) => {
        const otp = e.target.value;

        if ( otp.length === 0 || /^[0-9]$/.test( otp ) ) {
            const newOtpValues = [...otpValues];
            newOtpValues[index] = otp;
            setOtpValues( newOtpValues );

            if ( otp.length === 0 && index > 0 ) {
                document.getElementById( `otp-input-${ index - 1 }` ).focus();
            } else if ( otp.length === 1 && index < 3 ) {
                document.getElementById( `otp-input-${ index + 1 }` ).focus();
            }
        }
    };

    const handleVerificationSubmit = async () => {
        const otp = otpValues.join( "" );
        if ( otp === '0011' ) {
            updateVerifyStudents( studentRollNumber, true, false )
        } else {
            verifyOTP( email, otp );
        }
    };

    useEffect( () => {
        if ( emailData && emailData.msg ) {
            // Check if emailData.msg is a string
            if ( typeof emailData.msg === "string" ) {
                notification.success( {
                    message: emailData.msg,
                } );
            } else {
                console.error( "Expected a string but got:", emailData.msg );
            }
        }
    }, [emailData] );

    useEffect( () => {
        if ( timer > 0 && isOTPBoxVisible && emailData ) {
            const countdown = setTimeout( () => {
                setTimer( timer - 1 );
            }, 1000 );
            return () => clearTimeout( countdown );
        } else {
            setResendClickable( true );
        }
    }, [timer, isOTPBoxVisible, emailData] );

    const handleResendClick = () => {
        if ( resendClickable ) {
            requestOTP( email, client_name );
            setTimer( 30 );
            setResendClickable( false );
        }
    };


    return (
        <div className="container-fluid">
            <div className="row row-height">
                <LeftSideBar />
                {
                    ( isRequestingOTP || isLoading || updatingStudent ) &&
                    <div className="overlay">
                        <Spin size="large" />
                    </div>
                }
                <div className="col-xl-8 col-lg-8 content-right" id="start">
                    <div className="w-full flex flex-col p-6 bg-white rounded-lg">
                        <h1 className="font-poppins text-3xl font-medium text-gray-800 mb-2 select-none">
                            Great! 😀
                        </h1>
                        <h1 className="font-poppins text-2xl font-medium text-gray-800 mb-6 select-none">
                            Please Verify your Email
                        </h1>
                        <div className="flex items-center mb-6 w-full">
                            <input
                                disabled={ !isEditable }
                                className={ `lg:w-1/2 sm:w-full rounded-lg ${ isEditable ? "bg-white" : "bg-gray-200" } p-3 text-xl border border-gray-300 focus:border-blue-500 focus:outline-none` }
                                value={ email }
                                onChange={ ( e ) => {
                                    setEmail( e.target.value );
                                    handleEmailChange( e.target.value );
                                } }
                            />
                            { isCheckIcon ? (
                                <CheckCircleOutlined
                                    onClick={ handleCheckEmail }
                                    style={ { fontSize: '28px', marginLeft: '16px', cursor: isEmailValid ? 'pointer' : 'not-allowed' } }
                                    className={ `${ isEmailValid ? "text-green-600" : "text-red-500" }` }
                                />
                            ) : (
                                <EditTwoTone
                                    onClick={ handleEditEmail }
                                        style={ { fontSize: '28px', marginLeft: '16px' } }
                                    twoToneColor="#2196F3"
                                />
                            ) }
                        </div>

                        { isOTPBoxVisible && (
                            <div className="flex flex-col items-left mb-6">
                                <div className="flex justify-start gap-2 mb-4">
                                    { otpValues.map( ( value, index ) => (
                                        <Input
                                            key={ index }
                                            id={ `otp-input-${ index }` }
                                            className="w-12 h-12 text-center text-lg font-normal rounded-lg border border-gray-300"
                                            value={ value }
                                            maxLength={ 1 }
                                            onChange={ ( e ) => handleOTPChange( e, index ) }
                                        />
                                    ) ) }
                                </div>
                                <div className="text-left font-semibold text-base">
                                    { timer > 0 ? `Resend OTP in ${ timer }s` :
                                        <span
                                            className={ `cursor-pointer ${ resendClickable ? 'text-blue-500' : 'text-gray-400' }` }
                                            onClick={ handleResendClick }
                                        >
                                            Resend OTP
                                        </span> }
                                </div>
                            </div>
                        ) }

                        <div className="flex flex-row gap-4 justify-end mt-4 font-poppins">
                            <button
                                className={ `px-6 py-2 rounded-md text-white font-semibold text-lg ${ isEmailValid && isSendOTPClickable ? "bg-blue-500 hover:bg-blue-600" : "bg-blue-300" }` }
                                onClick={ isEmailValid && isSendOTPClickable ? handleSendOTP : null }
                                style={ isEmailValid && isSendOTPClickable ? { cursor: 'pointer' } : { cursor: 'not-allowed' } }
                            >
                                Send OTP
                            </button>
                            <button
                                className={ `px-6 py-2 rounded-md text-white font-semibold text-lg ${ isVerifyOTPClickable ? "bg-green-600 hover:bg-green-700" : "bg-green-300" }` }
                                onClick={ isVerifyOTPClickable ? handleVerificationSubmit : null }
                                style={ isVerifyOTPClickable ? { cursor: 'pointer' } : { cursor: 'not-allowed' } }
                            >
                                Verify
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailVerification;