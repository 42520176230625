import React, { useState, useEffect } from 'react';
import { dynamicData } from '../../helpers/data';
import { useSelector } from 'react-redux';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import useGeneratePdfReport from '../../api/useGeneratePdfReport';
import { Spin } from 'antd';
import ThankYouPageTwo from './ThankYouPageTwo';
import useSendMessageUser from '../../api/SendMessageUser';

export const ThankYouGlobalCareer = () => {

    const location = useLocation();
    const { formIds } = location.state || {};

    const query = new URLSearchParams( location.search );
    const isAllTestCompleted = query.get( 'completed' );
    const userEmail = query.get( 'user_email' );
    const userName = query.get( 'user_name' );
    const userId = query.get( 'user_id' );
    const clientConfig = useSelector( state => state.clientConfiguration );
    const formConfigRedux = useSelector( state => state.formConfiguration );

    const student_name = localStorage.getItem( 'studentName' ) || "abc";
    const student_email = localStorage.getItem( 'user_email' ) || "abc@gmail.com";

    const formConfig = formConfigRedux?.form_config;
    const clientid = formConfig?.client_id;
    const bookingLink = 'https://chat.whatsapp.com/DAWuHNvP6cO0aGQoaJcNxW';

    const { client_name } = useParams();
    const navigate = useNavigate();

    // const [reportLink, setReportLink] = useState( null );
    const [studentRollNumber, setStudentRollNumber] = useState( null );
    // const [isDownloaded, setIsDownloaded] = useState( false );

    // const { data: pdfReport, isLoading: pdfReportGenerating, generatePdfReport } = useGeneratePdfReport();
    const { SendMessageUser } = useSendMessageUser();

    // const handleDownloadReport = () => {
    //     setIsDownloaded( true )
    // };

    // useEffect( () => {
    //     if ( studentRollNumber ) {
    //         generatePdfReport( studentRollNumber, formIds );
    //     }
    // }, [studentRollNumber] );

    useEffect( () => {
        setStudentRollNumber( localStorage.getItem( 'student_roll_number' ) || localStorage.getItem( 'user_id' ) );
    }, [] );

    // useEffect( () => {
    //     if ( pdfReport && reportLink === null ) {
    //         setReportLink( pdfReport );
    //     }
    // }, [pdfReport] );

    // useEffect( () => {
    //     if ( reportLink && !pdfReportGenerating && isDownloaded ) {
    //         setIsDownloaded( false );
    //         try {
    //             const url = new URL( reportLink );
    //             window.open( url );
    //         } catch ( e ) {
    //             console.error( 'Invalid PDF report URL:', reportLink );
    //         }
    //     }
    // }, [reportLink, pdfReportGenerating, isDownloaded] );

    const handlementorshipbooking = () => {
        window.open( `${ bookingLink }`, '_blank' );
    };


    const handleProceedToTest = () => {
        navigate( `/${ client_name }/test/selection/?user_email=${ userEmail }&user_name=${ userName }&user_id=${ userId }`, { replace: true } )
    };

    useEffect( () => {
        if ( formIds && formIds[0] ) {
            SendMessageUser( studentRollNumber, formIds[0] );
        }
    }, [studentRollNumber, formIds] )

    return (
        <>
            {
                isAllTestCompleted ?
                    <>
                        <ThankYouPageTwo
                            handlementorshipbooking={ handlementorshipbooking }
                        />
                    </>
                    :
                    <div className='flex flex-col gap-6 justify-center font-poppins sm:w-full lg:w-2/3 text-center items-center'>
                        <h2 className="font-bold text-5xl leading-tight		">Thank You for Taking This Assessment! 🎉</h2>

                        <p className="font-medium text-base">There are just a few more assessments left before we can give you the report.</p>

                        <button
                            className="text-white text-center font-semibold text-lg rounded py-2 px-4 mb-2 w-fit"
                            style={ { backgroundColor: clientConfig.primary_color } }
                            onClick={ handleProceedToTest }
                        >
                            Go To Assessments
                        </button>
                    </div>
            }
        </>
    );
};
