import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useGeneratePdfReport from '../../api/useGeneratePdfReport';
import ThankYouPageFour from './ThankYouPageFour';
import ThankYouPageOne from './ThankYouPageOne';
import useSendMessageUser from '../../api/SendMessageUser';

export const ThankYouStride = () => {

    const student_name = localStorage.getItem( 'studentName' ) || "abc";
    const student_email = localStorage.getItem( 'user_email' ) || "abc@gmail.com";
    const [reportLink, setReportLink] = useState( null );
    const [userFormIds, setUserFormIds] = useState( null );
    const formConfigRedux = useSelector( state => state.formConfiguration );
    const [studentRollNumber, setStudentRollNumber] = useState( null );
    const [isDownloaded, setIsDownloaded] = useState( false );
    const clientConfig = useSelector( state => state.clientConfiguration );

    const location = useLocation();
    const { formIds } = location.state || {};

    const { client_name } = useParams();
    const navigate = useNavigate();

    const query = new URLSearchParams( location.search );
    const isAllTestCompleted = query.get( 'completed' );
    const userEmail = query.get( 'user_email' );
    const userName = query.get( 'user_name' );
    const userId = query.get( 'user_id' );
    const testSeriesId = localStorage.getItem( 'testSeriesId' ) === null
        ? ''
        : parseInt( localStorage.getItem( 'testSeriesId' ), 10 );

    const formConfig = formConfigRedux?.form_config;
    const formid = formConfig?.form_id;
    const bookingLink = formConfig?.booking_url;

    useEffect( () => {
        if ( formid ) {
            setUserFormIds( [formid] )
        } else if ( formIds ) {
            setUserFormIds( formIds )
        }
    }, [formIds, formid] )

    const { data: pdfReport, isLoading: pdfReportGenerating, generatePdfReport } = useGeneratePdfReport();
    const { data: sendMessageResponse, isLoading: sendingMessage, SendMessageUser } = useSendMessageUser();

    const handleBooking = () => {
        bookingLink && window.open( `${ bookingLink }/?name=${ student_name }&email=${ student_email }`, '_blank' );
    };

    useEffect( () => {
        if ( studentRollNumber ) {
            generatePdfReport( studentRollNumber, userFormIds );
        }
    }, [studentRollNumber] );

    useEffect( () => {
        setStudentRollNumber( localStorage.getItem( 'student_roll_number' ) );
    }, [] );

    const handleDownloadReport = () => {
        setIsDownloaded( true )
    };

    useEffect( () => {
        if ( pdfReport && reportLink === null ) {
            setReportLink( pdfReport );
        }
    }, [pdfReport] );

    useEffect( () => {
        if ( reportLink && !pdfReportGenerating && isDownloaded ) {
            setIsDownloaded( false );
            try {
                const url = new URL( reportLink );
                const downloadLink = document.createElement( "a" );
                downloadLink.style.display = "none";
                downloadLink.href = reportLink;

                // Append the anchor element to the document body
                document.body.appendChild( downloadLink );

                // Trigger a click event on the anchor to start the download
                downloadLink.click();

                // Remove the anchor from the document
                document.body.removeChild( downloadLink );
            } catch ( e ) {
                console.error( 'Invalid PDF report URL:', reportLink );
            }
        }
    }, [reportLink, pdfReportGenerating, isDownloaded] );

    useEffect( () => {
        if ( reportLink && userFormIds ) {
            SendMessageUser( studentRollNumber, userFormIds[0] );
        }
    }, [reportLink] )


    const handleProceedToTest = () => {
        navigate( `/${ client_name }/test/selection/?user_email=${ userEmail }&user_name=${ userName }&user_id=${ userId }`, { replace: true } )
    };


    return (
        <>
            {
                pdfReportGenerating && isDownloaded &&
                <div div className="overlay">
                    <Spin size="large" />
                </div>
            }
            {
                ( !Number.isInteger( testSeriesId ) ) ?
                    <>
                        <ThankYouPageOne
                            handleReport={ handleDownloadReport }
                            handlementorshipbooking={ handleBooking } />
                    </>
                    :
                    (
                        testSeriesId && isAllTestCompleted ?
                            <>
                                <ThankYouPageOne
                                    handleReport={ handleDownloadReport }
                                    handlementorshipbooking={ handleBooking } />
                            </>
                            :
                            <div className='flex flex-col gap-6 justify-center font-poppins sm:w-full lg:w-2/3 text-center items-center'>
                                <h2 className="font-bold text-5xl leading-tight		">Thank You for Taking This Assessment! 🎉</h2>

                                <p className="font-medium text-base">There are just a few more assessments left before we can give you the report.</p>

                                <button
                                    className="text-white text-center font-semibold text-lg rounded py-2 px-4 mb-2 w-fit"
                                    style={ { backgroundColor: clientConfig.primary_color } }
                                    onClick={ handleProceedToTest }
                                >
                                    Go To Assessments
                                </button>
                            </div>
                    )
            }
        </>
    );
};
