import { useState } from 'react';
import axios from 'axios';
import {
    BASE_URL, CREATE_STUDENT
} from '../utils/constants/constants';

const useCreateStudentCode = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );

    const createStudentCode = async ( studentName, studentGrade, studentEmail, clientName, responseId, studentDateOfBirth, clientId, studentPh ) => {
        setIsLoading( true );
        const API_URL = `${ BASE_URL }${ CREATE_STUDENT }`;

        const requestBody = {
            name: studentName,
            grade: studentGrade,
            email: studentEmail,
            client: clientName,
            dob: studentDateOfBirth,
            client_id: clientId,
        };

        if ( responseId ) {
            requestBody.response_id = responseId;
        }

        if ( studentPh ) {
            requestBody.phone = studentPh;
        }

        try {
            const res = await axios.post( API_URL, requestBody );
            localStorage.setItem( 'student_roll_number', res.data.student_code );
            setResponse( res.data );
            setError( null );
        } catch ( err ) {
            setError( err );
        } finally {
            setIsLoading( false );
        }
    };

    return { response, error, isLoading, createStudentCode };
};

export default useCreateStudentCode;
