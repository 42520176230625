import React, { useState, useEffect } from 'react';
import ThankYouPageTwo from './ThankYouPageTwo';

const ThankyouTTM = () => {
    const [userAssessmentResponseID, setUserAssessmentResponseID] = useState( null );
    const [userName, setUserName] = useState( 'UserName' );
    const [savedEmail, setSavedEmail] = useState( 'default@email.com' );

    useEffect( () => {
        const user_email = localStorage.getItem( 'user_email' );
        const userAssessmentResponseID = localStorage.getItem( 'userAssessmentResponseID' );
        const user_name = localStorage.getItem( 'user_name' ) || localStorage.getItem( 'studentName' );

        setUserName( user_name );
        setSavedEmail( user_email );
        setUserAssessmentResponseID( userAssessmentResponseID )
    }, [] );

    const handleReport = () => {
        window.open( `https://reports.strideahead.io/${ userAssessmentResponseID }?user=${ userName }&email=${ savedEmail }`, '_blank' );
    }

    const handlementorshipbooking = () => {
        window.open( `https://bookings.strideahead.io/team-stride/mentorship-session?name=${ userName }&email=${ savedEmail}`, '_blank' );
    }

    return (
        <ThankYouPageTwo
            handleReport={ handleReport }
            handlementorshipbooking={ handlementorshipbooking }
        />
    );
};

export default ThankyouTTM