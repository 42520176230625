//import { useState, useEffect, useRef } from 'react';
//import axios from 'axios';
//import { BASE_URL, FETCH_FORM_CONFIG } from '../utils/constants/constants';
//import { notification } from 'antd';

//const useFetchFormConfig = ( clientName, formType, testId ) => {
//    const [response, setResponse] = useState( null );
//    const [error, setError] = useState( null );
//    const [isLoading, setIsLoading] = useState( false );
//    const isFirstMount = useRef( true );

//    useEffect( () => {
//        if ( isFirstMount.current ) {
//            setIsLoading( true );

//            const fetchFormConfig = async ( clientName, formType, testId ) => {
//                try {
//                    const res = await axios.get( `${ BASE_URL }${ FETCH_FORM_CONFIG }/?client_code=${ clientName }&form_type=${ formType }&test_id=${ testId }` );
//                    if ( !res.data.status_code ) {
//                        setResponse( res );
//                        setError( null );
//                    } else {
//                        notification.error( {
//                            message: 'Error',
//                        } );
//                    }
//                } catch ( err ) {
//                    setError( err );
//                    console.log( err );
//                } finally {
//                    setIsLoading( false );
//                }
//            };
//            fetchFormConfig( clientName, formType, testId );
//            isFirstMount.current = false
//        }
//    }, [] );

//    return { response, error, isLoading };
//};

//export default useFetchFormConfig;

import { useState } from "react";
import axios from "axios";
import { BASE_URL, FETCH_FORM_CONFIG } from '../utils/constants/constants';

const useFetchFormConfig = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );

    const fetchFormConfig = async ( clientName, formType, testId ) => {
        setIsLoading( true );

        try {
            const res = await axios.get( `${ BASE_URL }${ FETCH_FORM_CONFIG }/?client_code=${ clientName }&form_type=${ formType }&test_id=${ testId }` );
            setResponse( res );
            setError( null );
        } catch ( err ) {
            setError( err );
            console.log( err );
        } finally {
            setIsLoading( false );
        }
    };

    return { response, error, isLoading, fetchFormConfig };
};

export default useFetchFormConfig;