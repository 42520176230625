import { Radio } from "antd";
import React, { useState, useEffect } from "react";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics/index";
import { singleChoiceCategory, singleChoiceAction } from "../../utils/GoogleAnalytics/events/categories";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useMedia } from 'react-use';
import { useSelector } from 'react-redux';

const useImageDimensions = ( src, maxWidth, maxHeight ) => {
    const [dimensions, setDimensions] = useState( { width: 0, height: 0 } );

    useEffect( () => {
        const image = new Image();

        image.onload = () => {
            const aspectRatio = image.width / image.height;

            let newHeight = maxHeight;
            let newWidth = maxHeight * aspectRatio;

            if ( newWidth > maxWidth ) {
                newWidth = maxWidth;
                newHeight = maxWidth / aspectRatio;
            }

            setDimensions( { width: newWidth, height: newHeight } );
        };

        image.src = src;
    }, [src, maxWidth, maxHeight] );

    return dimensions;
};

const RenderOptionText = ( { option } ) => {
    const maxWidth = window.innerWidth - 150; // Use screen width

    const isWide = useMedia( '(min-width: 450px)' );
    const maxHeight = isWide ? 200 : 50; // Define max height

    // Move the image source extraction logic outside of the conditional block
    const imgSrcMatch = option.value.match( /src=["'](.*?)["']/ );
    const imgSrc = imgSrcMatch ? imgSrcMatch[1] : null;

    // Always call the hook
    const dimensions = useImageDimensions( imgSrc, maxWidth, maxHeight );

    if ( imgSrc ) {
        // Adjust width and height as needed
        return (
            <span
                dangerouslySetInnerHTML={ {
                    __html: option.value.replace(
                        /<img/g,
                        `<img style="max-width: ${ dimensions.width }px; height: ${ dimensions.height }px;"`
                    ),
                } }
            />
        );
    } else {
        return <span>{ option.value }</span>;
    }
};

const CustomRadio = ( { option, selected, onSelect, onNext } ) => {

    const handleKeyDown = ( e ) => {
        if ( e.key === "Enter" ) {
            onNext();
        }
    };

    return (
        <Radio
            className="flex items-center cursor-pointer bg-white p-2 rounded-md border-2 p-3 mt-2"
            value={ option.id }
            checked={ selected === option.id }
            onChange={ () => onSelect( option?.id ) }
            onKeyDown={ handleKeyDown }
            tabIndex={ 0 }
        >
            <RenderOptionText option={ option } />
        </Radio>
    );
};

const QuestionStep = ( { question, selected, onSelect, onNext, onBack, onClearAll, isPagination, isQuestionNavigation, isValidation } ) => {
    const [isOptionSelected, setIsOptionSelected] = useState( false );
    const clientConfig = useSelector( state => state.clientConfiguration );

    const handleSelect = ( option ) => {
        onSelect( option );
        isValidation( true );
        setIsOptionSelected( true );
        TrackGoogleAnalyticsEvent( singleChoiceCategory, singleChoiceAction );

        if ( question.text.includes( "Hey Fellow Learner! What grade are you in?" ) ) {
            localStorage.setItem( 'studentGrade', option.value );
        } else if ( question.text.includes( "Would you prefer the test to be online or offline?" ) ) {
            localStorage.setItem( 'examination_mode', option.value )
        } else if ( question.text.includes( "What grade are you in right now?" ) ) {
            localStorage.setItem( 'studentGrade', option.value );
        } else if ( question.text.includes( 'How many years of experience you have?' ) ) {
            localStorage.setItem( 'experience', option.value );
        }
    };

    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    useEffect( () => {
        if ( selected ) {
            isValidation( true );
            setIsOptionSelected( true );
        } else {
            setIsOptionSelected( false );
        }
    }, [question, selected] );

    const handleClearAll = ( option ) => {
        onClearAll( option )
    }

    return (
        <div className={ `flex flex-col justify-between ${ isPagination ? "mb-4" : "mt-2 lg:h-full" }` }>
            <div className="flex flex-col lg:h-full overflow-y-auto">
                <QuestionAndDescription question={ question } />
                <div className="space-y-2 mt-3 ">
                    { question?.options?.map( ( option ) => (
                        <CustomRadio
                            key={ option.id }
                            option={ option }
                            selected={ selected }
                            onSelect={ handleSelect }
                            onNext={ onNext }
                        />
                    ) ) }
                </div>
            </div>
            {
                isPagination ?
                    <></>
                    :
                    isQuestionNavigation ?
                        <>
                            <SubmitButton onNext={ onNext } onClearAll={ handleClearAll } />
                        </>
                        :
                        <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                            {
                                question?.question_optional === "optional" && (
                                    <button className="skip-button" onClick={ onNext } >
                                        Skip
                                    </button>
                                )
                            }
                            {
                                perQuestionTimer ?
                                    <></> :
                                    <button
                                        className="back-button"
                                        onClick={ onBack } >
                                        Back
                                    </button>

                            }
                            <button
                                className="next-button"
                                onClick={ isOptionSelected ? onNext : null }
                                style={ {
                                    backgroundColor: isOptionSelected ? clientConfig.primary_color : `${ clientConfig.primary_color }80`,
                                    cursor: isOptionSelected ? 'pointer' : 'not-allowed'
                                } }
                            >
                                Next
                            </button>
                        </div>
            }
        </div>
    );
};

export default QuestionStep;