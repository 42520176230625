import React, { useState, useEffect } from "react";
import { dynamicData } from "../../helpers/data";
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { notification } from "antd";
import useFetchFormsByUserId from "../../api/FetchFormsUserId";
import Lottie from "lottie-react";
import animationData from '../../assets/Animation/loading_animation.json'
import "./instructions.css";

const OverallInstructions = () => {
    const [queryParams, setQueryParams] = useState( '' );
    const navigate = useNavigate();
    const { client_name } = useParams();
    const [isChecked, setIsChecked] = useState( false );
    const { response: formsData, isLoading: formsdataLoading, FetchUserForms } = useFetchFormsByUserId();

    const clientConfig = useSelector( state => state.clientConfiguration );
    const TestSeriesData = JSON.parse( sessionStorage.getItem( 'TestSeriesDetails' ) );

    const location = useLocation();
    const query = new URLSearchParams( location.search );
    const user_id = query.get( 'user_id' );
    const testSeriesID = localStorage.getItem( 'testSeriesId' );

    useEffect( () => {
        const currentParams = new URLSearchParams( window.location.search );
        setQueryParams( currentParams.toString() );
    }, [] );

    const handleGoToTestSelection = () => {
        if ( isChecked ) {
            navigate( `/${ client_name }/test/selection/?${ queryParams }`, { replace: true } );
        } else {
            notification.info( {
                message: 'Please read all the instructions.'
            } )
        }
    }

    const handleCheckboxChange = ( event ) => {
        setIsChecked( event.target.checked );
    };

    useEffect( () => {
        if ( client_name ) {
            FetchUserForms( client_name, user_id, 'test', testSeriesID )
        }
    }, [client_name] )

    useEffect( () => {
        if ( formsData ) {
            sessionStorage.setItem( 'UserResponse', JSON.stringify( formsData ) );
        }
    }, [formsData] )

    return (
        <div className="w-full max-w-screen flex flex-col font-poppins gap-[1rem] lg:px-[120px] sm:px-[40px] pb-[2rem]">
            {
                formsdataLoading &&
                <div className="flex items-center justify-center h-screen" >
                    <Lottie animationData={ animationData } loop autoplay style={ { width: '250px', height: '250px' } } />
                </div>
            }
            <div className="flex justify-center items-center w-full h-24">
                <div
                    className="flex justify-center items-center lg:h-[150px] sm:h-[50px] lg:w-[250px] sm:w-[100px] w-1/2"
                    style={ { maxWidth: "100%", maxHeight: "100%" } }
                >
                    <img
                        src={ clientConfig?.logo || dynamicData.default.default_left_section.logo_link || "" }
                        alt="logo"
                        className="max-h-full max-w-full"
                    />
                </div>
            </div>
            {/* <div className='w-full flex justify-center items-center mb-4'>
                <p className='text-lg font-semibold'>
                    Assessment Instructions.
                </p>
            </div> */}
            <div className="space-y-4">
                <div
                    className="font-poppins text-lg custom-list"
                    dangerouslySetInnerHTML={ { __html: TestSeriesData?.instructions || formsData?.test_series_instructions } }
                />
            </div>

            <div className="w-full flex lg:flex-row sm:flex-col sm:gap-6 justify-between bg-gray-200 rounded-xl p-4">
                <div className='flex lg:items-center sm:items-start'>
                    <input
                        type="checkbox"
                        className="form-checkbox lg:h-5 lg:w-5 sm:w-8 sm:h-8 text-gray-600"
                        checked={ isChecked }
                        onChange={ handleCheckboxChange }
                    />
                    <p className="text-black font-semibold lg:text-lg lg:ml-2 sm:text-sm sm:ml-4">
                        I have read all the above instructions and ready to take the test
                    </p>
                </div>

                <button
                    className="text-white font-semibold py-3 px-4 rounded"
                    style={ {
                        backgroundColor: isChecked ? clientConfig.primary_color : `${ clientConfig.primary_color }80`,
                        cursor: isChecked ? 'pointer' : 'not-allowed'
                    } }
                    onClick={ handleGoToTestSelection }

                >
                    Proceed to Test
                </button>
            </div>
        </div>
    );
};

export default OverallInstructions;