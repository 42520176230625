import React, { useState, useEffect } from "react";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics/index";
import { numberTypeCategory, numberTypeAction } from "../../utils/GoogleAnalytics/events/categories";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { useParams, useLocation } from 'react-router-dom';
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";
import useFetchSchoolData from "../../api/FetchSchoolData";
import { notification } from "antd";
import { useSelector } from 'react-redux';

const NumberInputStep = ( { question, answer, onAnswerChange, onNext, onBack, isPagination, isQuestionNavigation, isValidation, onClearAll } ) => {
    const [isAnswerNumeric, setIsAnswerNumeric] = useState( false );

    const { fetchData, response, error, isLoading } = useFetchSchoolData();
    const clientConfig = useSelector( state => state.clientConfiguration );

    const { client_name } = useParams();
    const location = useLocation();
    const query = new URLSearchParams( location.search );
    const institute_code = dynamicData.default.institute_id || localStorage.getItem( 'schoolId' ) || query.get( 'institute_id' );

    const [schoolId, setSchoolId] = useState( institute_code || null );

    useEffect( () => {
        if ( answer ) {
            setIsAnswerNumeric( true );
            isValidation( true );
        } else if ( institute_code && question.text.includes( "Enter your school ID" ) ) {
            setIsAnswerNumeric( true );
            isValidation( true );
        } else {
            setIsAnswerNumeric( false );
            isValidation( false );
        }
    }, [question, answer] );

    const handleInputChange = ( e ) => {
        const inputValue = e.target.value;

        // Use a regular expression to filter out non-numeric characters
        const numericValue = inputValue.replace( /[a-zA-Z]/g, "" );;
        console.log( numericValue );
        // Add "SCH-" before the numeric value
        const formattedValue = `SCH-${ numericValue }`;

        setIsAnswerNumeric( !!numericValue ); // Check if there are numeric digits
        isValidation( !!numericValue );
        setSchoolId( numericValue );
        onAnswerChange( numericValue );
        TrackGoogleAnalyticsEvent( numberTypeCategory, numberTypeAction );
    };

    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    const handleKeyDown = ( e ) => {
        if ( e.key === "Enter" ) {
            onNext();
        }
    };


    const handleSubmit = async () => {
        if ( question.text.includes( "Enter your school ID" ) ) {
            try {
                const responseData = await fetchData( schoolId );
                // handle success using responseData
                notification.success( {
                    message: 'School Id Verified',
                } );
                localStorage.setItem( 'schoolId', schoolId );
                localStorage.setItem( 'schoolName', responseData?.name );
                onNext();
            } catch ( error ) {
                // handle error
                notification.error( {
                    message: 'Incorrect School Id',
                    description: 'The school id entered is incorrect. Please try again.',
                } );
            }
        } else {
            onNext();
        }
    };

    const handleClearAll = () => {
        handleInputChange( { target: { value: '' } } );
        onClearAll()
    }

    return (
        <div className={ `flex flex-col justify-between ${ isPagination ? "mb-4" : "mt-2 lg:h-full" }` }>
            <div className="flex flex-col">
                <QuestionAndDescription question={ question } />
                <div>
                    <input
                        type="text"
                        className="w-1/2 sm:w-full lg:w-1/2 rounded bg-white p-3 border-2 focus:border-blue-500"
                        value={
                            question.text.includes( "Enter your school ID" )
                                ? institute_code
                                : answer
                        }
                        onChange={ ( e ) => handleInputChange( e ) }
                        placeholder={ question?.placeholder_text }
                        disabled={ institute_code && question.text.includes( "Enter your school ID" ) }
                        onKeyDown={ handleKeyDown }
                    />
                </div>
            </div>
            {
                isPagination ?
                    <></>
                    :
                    isQuestionNavigation ?
                        <>
                            <SubmitButton onNext={ onNext } onClearAll={ handleClearAll } />
                        </>
                        :
                        <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                            { question?.question_optional === "optional" && (
                                <button className="skip-button" onClick={ onNext }>
                                    Skip
                                </button>
                            ) }
                            {
                                perQuestionTimer ?
                                    <></> :
                                    <button className="back-button" onClick={ onBack } >
                                        Back
                                    </button>

                            }
                            <button
                                className="next-button"
                                onClick={ isAnswerNumeric ? handleSubmit : null }
                                style={ {
                                    backgroundColor: isAnswerNumeric ? `${ clientConfig.primary_color }` : `${ clientConfig.primary_color }80`,
                                    cursor: isAnswerNumeric ? 'pointer' : 'not-allowed'
                                } }
                            >
                                Next
                            </button>
                        </div>
            }
        </div>
    );
};

export default NumberInputStep;
