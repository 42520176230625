import React from 'react';
import { Modal } from 'antd';
import Instructions from '../MultiStepForm/InstructionPage/Instructions';
import { useParams, useNavigate } from 'react-router-dom';

const AuthModal = ( { isUserRegistered } ) => {

    const navigate = useNavigate();
    const { client_name } = useParams();

    const handleProceed = () => {
        navigate( `/${ client_name }/test/signup`, { replace: true } );
    }

    return (
        <Modal
            visible={ !isUserRegistered }
            centered
            closable={ false }
            maskClosable={ false }
            footer={ null } // Remove the cancel button
            width="100%" // Full width
            style={ { top: 0, left: 0, right: 0, bottom: 0, margin: 0 } } // Full screen positioning
            bodyStyle={ { height: '90vh', overflowY: 'auto', padding: 0 } } // Full screen body
        >
            <Instructions customProceed={ true } handleProceed={ handleProceed } />
        </Modal>
    );
}

export default AuthModal;
