import React from 'react';
import { Modal, Button } from 'antd';

const InstructionsModalTest = ( { instructions, isOpen, onOk } ) => {
    return (
        <Modal
            open={ isOpen }
            onCancel={ onOk }
            footer={ null }
            closeIcon={ false }
            centered={ true }
            width={ 1000 }
            onOk={ onOk }
            okButtonProps={ { style: { backgroundColor: '#3b82f6' } } }
            bodyStyle={ { height: '70vh' } }
        >   
            <div className='flex flex-col h-full'>
                <div className='h-full overflow-auto'>
                    <p className="font-poppins text-xl font-bold mb-4">Instructions</p>
                    <div
                        className="font-poppins text-base custom-list"
                        dangerouslySetInnerHTML={ { __html: instructions } }
                        style={ { height: '100%' } } // Adjust height and add scrolling
                    />
                </div>
                <div style={ { textAlign: 'right', marginTop:'10px' } }>
                    <Button type="primary" onClick={ onOk } style={ { backgroundColor: '#3b82f6' } }>
                        OK
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default InstructionsModalTest;
