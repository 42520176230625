import React, { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Modal } from 'antd';

const InstructionModal = ( { isModalOpen, handleOk, handleCancel, setIsVideoCompleted, youtubeURL, testId } ) => {
    const playerRef = useRef( null );
    const playerWidth = 640; // Set desired width for the player
    const playerHeight = 360; // Set desired height for the player

    useEffect( () => {
        let interval;
        if ( isModalOpen ) {
            interval = setInterval( () => {
                if ( playerRef.current ) {
                    const currentTime = playerRef.current.getCurrentTime() + 2;
                    const totalDuration = playerRef.current.getDuration();

                    if ( totalDuration && currentTime && currentTime >= totalDuration ) {
                        setIsVideoCompleted( testId, true );
                    }
                }
            }, 1000 ); // Logs every second
        }

        return () => clearInterval( interval ); // Cleanup the interval when modal is closed
    }, [isModalOpen] );

    return (
        <div>
            <Modal
                title="Instructions"
                open={ isModalOpen }
                onCancel={ handleCancel }
                cancelText="Go Back"
                centered={ true }
                okButtonProps={ { style: { backgroundColor: '#3b82f6' } } }
                width={ playerWidth + 32 } // Add padding for the modal
                bodyStyle={ { padding: 0 } } // Remove default padding for modal body

            >
                <div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }>
                    <ReactPlayer
                        ref={ playerRef }
                        url={ youtubeURL }
                        playing
                        controls
                        width={ playerWidth }
                        height={ playerHeight }
                    />
                </div>
            </Modal>
        </div>
    );
};

export default InstructionModal;
