import { useState } from 'react';
import axios from 'axios';
import {
    BASE_URL, UPDATE_USER_QUESTION_RESPONSE
} from '../utils/constants/constants';


const useUpdateUserQuestionResponse = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );

    const updateUserQuestionResponse = async ( optionIDs = [], textResponse, answerResponseid ) => {
        setIsLoading( true );
        const safeOptionIDs = Array.isArray( optionIDs ) ? optionIDs : [optionIDs];
        const optionsArray = safeOptionIDs.map( id => ( { option_id: id } ) );
        const requestBody = {
            text_response: textResponse,
            options: optionsArray,
        };

        const API_URL = `${ BASE_URL }${ UPDATE_USER_QUESTION_RESPONSE }/${ answerResponseid }`

        try {
            const res = await axios.put( API_URL, requestBody );
            setResponse( res.data );
            setError( null );
        } catch ( err ) {
            setError( err );
        } finally {
            setIsLoading( false );
        }
    };

    return { updateUserQuestionResponse, response, error, isLoading };
};

export default useUpdateUserQuestionResponse;
