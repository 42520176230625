import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LeftSideBar from '../LeftSideBar';
import '../../css/style.css';
import "../../css/bootstrap.min.css";
import CollegeSurvey from '../../assets/college_form.png';
import WorkingProfessional from '../../assets/professional_survey.png';
import ParentForm from '../../assets/parent_form.png';
import StudentForm from '../../assets/student_form.png';
import SchoolForm from '../../assets/school_form.png'
import StudentRegistration from '../../assets/student_registration_form.png'
import { useDispatch, useSelector } from 'react-redux';
import { setIsStudent, setIsParent, setIsConsentForm, setClientConfiguration } from '../../redux/student/studentSlice';
import { dynamicData } from '../../helpers/data';
import { useParams } from 'react-router-dom';
import useFetchClientConfig from '../../api/FetchClientConfig';

const FormSelector = () => {
    const dispatch = useDispatch();
    const [selected, setSelected] = useState( "" );
    const [userType, setUserType] = useState( '' );
    const [testType, setTestType] = useState( '' );

    const clientConfiguration = sessionStorage.getItem( 'clientConfiguration' );

    const { client_name } = useParams();

    const { response: clientConfigData, isLoading: clientConfigLoading, fetchClientConfig } = useFetchClientConfig();

    useEffect( () => {
        if ( clientConfigData && clientConfigData?.data ) {
            dispatch( setClientConfiguration( clientConfigData.data ) );
            sessionStorage.setItem( 'clientConfiguration', JSON.stringify( clientConfigData.data ) );

            const timeout = setTimeout( () => {
                sessionStorage.removeItem( 'clientConfiguration' );
                fetchClientConfig( client_name );
            }, 10 * 60 * 1000 );
            return () => clearTimeout( timeout );
        }
    }, [clientConfigData, client_name] );

    useEffect( () => {
        if ( client_name && !clientConfiguration ) {
            fetchClientConfig( client_name );
        }
    }, [client_name] )

    const is_college_form = true;
    const is_parent_form = false;
    const is_student_form = false;
    const is_working_professional_form = true;

    const is_school_registration_form = false;
    const is_student_registration_form = false;

    const selectCard = ( card ) => {
        setSelected( card );
        let productCode = "";
        if ( card === "college" ) {
            setUserType( 'college_form' );
            setTestType( 'survey' );
            productCode = "COLLEGE_SURVEY";
        } else if ( card === "working" ) {
            setUserType( 'working_professional_form' );
            setTestType( 'survey' );
            productCode = "PROFESSIONAL_SURVEY";
        } else if ( card === "student" ) {
            setUserType( 'student_form' );
            setTestType( 'survey' );
            dispatch( setIsStudent( true ) );
            dispatch( setIsParent( false ) );
        } else if ( card === "parent" ) {
            setUserType( 'parent_form' );
            setTestType( 'survey' );
            dispatch( setIsParent( true ) );
            dispatch( setIsStudent( false ) );
        }
        localStorage.setItem( 'product_code', productCode );
    };

    const handleProceedToQuiz = () => {
        dispatch( setIsConsentForm( false ) );
    };

    return (
        <div className="container-fluid">
            <div className="row row-height">
                <LeftSideBar />
                <div className="col-xl-8 col-lg-8 content-right" id="start">
                    <>
                        <div id="wizard_container">
                            <div className="flex flex-col items-center mt-20 space-y-8">
                                <h1 className="text-4xl font-medium">I'm a</h1>
                                <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-28 gap-y-8">
                                    { is_college_form && (
                                        <div
                                            onClick={ () => selectCard( "college" ) }
                                            className={ `flex flex-col items-center rounded-md shadow-md p-4 transition-transform transform hover:translate-y-[-5px] ${ selected === "college" ? "border-4 border-green-500" : ""
                                                }` }
                                        >
                                            <img src={ CollegeSurvey } alt="Image 1" className="w-32 h-32 rounded-full" />
                                            <h3 className="text-sm font-medium">College Student </h3>
                                        </div>
                                    ) }
                                    { is_student_form && (
                                        <div
                                            onClick={ () => selectCard( "student" ) }
                                            className={ `flex flex-col items-center rounded-md shadow-md p-4 transition-transform transform hover:translate-y-[-5px] ${ selected === "student" ? "border-4 border-green-500" : ""
                                                }` }
                                        >
                                            <img src={ StudentForm } alt="Image 1" className="w-32 h-32 rounded-full" />
                                            <h3 className="text-sm font-medium">Student</h3>
                                        </div>
                                    ) }
                                    { is_student_registration_form && (
                                        <div
                                            onClick={ () => selectCard( "student_registration" ) }
                                            className={ `flex flex-col items-center rounded-md shadow-md p-4 transition-transform transform hover:translate-y-[-5px] ${ selected === "student_registration" ? "border-4 border-green-500" : ""
                                                }` }
                                        >
                                            <img src={ StudentRegistration } alt="Image 1" className="w-32 h-32 rounded-full" />
                                            <h3 className="text-sm font-medium">Student Registration</h3>
                                        </div>
                                    ) }
                                    { is_school_registration_form && (
                                        <div
                                            onClick={ () => selectCard( "school_registration" ) }
                                            className={ `flex flex-col items-center rounded-md shadow-md p-4 transition-transform transform hover:translate-y-[-5px] ${ selected === "school_registration" ? "border-4 border-green-500" : ""
                                                }` }
                                        >
                                            <img src={ SchoolForm } alt="Image 1" className="w-32 h-32 rounded-full" />
                                            <h3 className="text-sm font-medium">School Registration</h3>
                                        </div>
                                    ) }
                                    { is_parent_form && (
                                        <div
                                            onClick={ () => selectCard( "parent" ) }
                                            className={ `flex flex-col items-center rounded-md shadow-md p-4 transition-transform transform hover:translate-y-[-5px] ${ selected === "parent" ? "border-4 border-green-500" : ""
                                                }` }
                                        >
                                            <img src={ ParentForm } alt="Image 2" className="w-32 h-32 rounded-full" />
                                            <h3 className="text-sm font-medium">Parent </h3>
                                        </div>
                                    ) }
                                    { is_working_professional_form && (
                                        <div
                                            onClick={ () => selectCard( "working" ) }
                                            className={ `flex flex-col items-center rounded-md shadow-md p-4 transition-transform transform hover:translate-y-[-5px] ${ selected === "working" ? "border-4 border-green-500" : ""
                                                }` }
                                        >
                                            <img src={ WorkingProfessional } alt="Image 2" className="w-32 h-32 rounded-full" />
                                            <h3 className="text-sm font-medium">Working Professional</h3>
                                        </div>
                                    ) }
                                </div>
                                <div className="mt-8">
                                    <Link
                                        to={ `/${ client_name }/${ userType }/${ testType }` }
                                    >
                                        <button className={ `px-4 py-2 rounded-md border-2 text-white bg-blue-500` }>
                                            Start Now
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            </div>
        </div>
    );
};

export default FormSelector;
