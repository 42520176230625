// useBlockNavigation.js
import { useEffect } from "react";

const useBlockNavigation = (shouldBlock) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (shouldBlock) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [shouldBlock]);

  useEffect(() => {
    const handleNavigate = (event) => {
      if (shouldBlock) {
        const confirmLeave = window.confirm("You have unsaved changes. Are you sure you want to leave?");
        if (!confirmLeave) {
          event.preventDefault();
        }
      }
    };

    window.addEventListener("popstate", handleNavigate);

    return () => {
      window.removeEventListener("popstate", handleNavigate);
    };
  }, [shouldBlock]);
};

export default useBlockNavigation;
