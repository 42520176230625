import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { indianCities } from "../../helpers/data";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { useParams } from 'react-router-dom';
import { dynamicData } from "../../helpers/data";
import { useSelector } from 'react-redux';

const { Option } = Select;

const IndianCitiesSelectionQuestion = ( { question, answer, onAnswerChange, onNext, onBack, isPagination, isQuestionNavigation, isValidation } ) => {
  const [indianCity, setIndianCity] = useState( null );
  const [isOptionSelected, setIsOptionSelected] = useState( false );
  const clientConfig = useSelector( state => state.clientConfiguration );

  const handleIndianCityChange = ( value ) => {
    setIndianCity( value );
    onAnswerChange( value );
    setIsOptionSelected( true );
    isValidation( true );
  };

  useEffect( () => {
    if ( answer && indianCities.includes( answer ) ) {
      setIndianCity( answer );
      setIsOptionSelected( true );
      isValidation( true );
    } else {
      isValidation( false );
    }
  }, [question, answer] );

  const { client_name } = useParams();

  const perQuestionTimer = dynamicData.default.perQuestionTimer;

  return (
    <div className={`flex flex-col justify-between ${isPagination ? "mb-4" : "mt-2 lg:h-full" }`}>
      <div className="flex flex-col">
        <QuestionAndDescription question={ question } />
        <div className="space-y-2 mt-4">
          <Select
            showSearch={ true }
            placeholder="Enter your City"
            className="w-1/2 sm:w-full lg:w-1/2"
            value={ indianCity }
            onChange={ handleIndianCityChange }
          >
            { indianCities.map( ( city, index ) => (
              <Option key={ index } value={ city }>
                { city }
              </Option>
            ) ) }
          </Select>
        </div>
      </div>
      {
        isPagination ?
          <></>
          :
          <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
            {
              question?.question_optional === "optional" && (
                <button className="skip-button" onClick={ onNext } >
                  Skip
                </button>
              )
            }
            {
              perQuestionTimer ?
                <></> :
                <button className="back-button" onClick={ onBack } >
                  Back
                </button>

            }
            <button
              className="next-button"
              onClick={ isOptionSelected ? onNext : null }
              style={ {
                backgroundColor: isOptionSelected ? `${ clientConfig.primary_color }` : `${ clientConfig.primary_color }80`,
                cursor: isOptionSelected ? 'pointer' : 'not-allowed'
              } }
            >
              Next
            </button>
          </div>
      }
    </div>
  );
};

export default IndianCitiesSelectionQuestion;
