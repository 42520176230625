export const TRACKING_ID = process.env.REACT_APP_TRACKING_ID_GOOGLE_ANALYTICS;
export const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const PROPERTY_ID = process.env.REACT_APP_PROPERTY_ID;
export const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
export const AWS_SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
export const AWS_REGION = process.env.REACT_APP_AWS_REGION;
export const S3_BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_NAME;
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const CREATE_USER_ASSESSMENT_RESPONSE = process.env.REACT_APP_CREATE_USER_ASSESSMENT_RESPONSE_API;
export const CREATE_USER_QUESTION_RESPONSE = process.env.REACT_APP_CREATE_USER_QUESTION_RESPONSE_API;
export const UPDATE_USER_QUESTION_RESPONSE = process.env.REACT_APP_UPDATE_USER_QUESTION_RESPONSE_API;
export const FORM = process.env.REACT_APP_FORM_API;
export const TERMS_AND_CONDITIONS = process.env.REACT_APP_TERMS_AND_CONDITIONS;
export const CREATE_SCHOOL = process.env.REACT_APP_CREATE_SCHOOL;
export const CREATE_STUDENT = process.env.REACT_APP_CREATE_STUDENTS;
export const READ_SCHOOL = process.env.REACT_APP_READ_SCHOOL;
export const GENERATE_OTP_EMAIL = process.env.REACT_APP_VERIFY_GENERATE_OTP_EMAIL;
export const AUTHENTICATE_STUDENT = process.env.REACT_APP_AUTHENTICATE_STUDENTS;
export const ASKIITIAN_TERMS_AND_CONDITIONS = process.env.REACT_APP_ASKIITIAN_TERMS_AND_CONDITIONS;
export const FETCH_PDF_REPORT = process.env.REACT_APP_FETCH_PDF_REPORT;
export const FETCH_DATA = process.env.REACT_APP_FETCH_DATA_URL;
export const UPDATE_USER_RESPONSE = process.env.REACT_APP_UPDATE_USER_RESPONSE;
export const FETCH_DETAILS_FROM_EMAIL = process.env.REACT_APP_FETCH_DETAILS_FROM_EMAIL;
export const VERIFY_COUPON_CODE = process.env.REACT_APP_VERIFY_COUPON_CODE;
export const FETCH_CLIENT_CONFIG = process.env.REACT_APP_FETCH_CLIENT_CONFIG;
export const FETCH_FORM_CONFIG = process.env.REACT_APP_FETCH_FORM_CONFIG
export const GENERATE_PDF_REPORT = process.env.REACT_APP_GENERATE_PDF_REPORT;
export const SEND_MESSAGE = process.env.REACT_APP_SEND_MESSAGE;
export const PUT_VERIFY_STUDENT = process.env.REACT_APP_VERIFY_STUDENT_PUT;
export const MULTIPLE_QUESTION_RESPONSES = process.env.REACT_APP_MULTIPLE_QUESTION_RESPONSES;
export const FETCH_FLOW_BLOCKS_BY_ID = process.env.REACT_APP_FETCH_FLOW_BLOCKS_BY_TEST_ID;
export const FETCH_FORMS_BY_USER_ID = process.env.REACT_APP_FETCH_TESTS_BY_USER_ID;
export const UPDATE_MULTIPLE_QUESTION_RESPONSE = process.env.REACT_APP_UPDATE_MULTIPLE_QUESTION_RESPONSE;

export const STRIDE_AUTH_URL = process.env.REACT_APP_STRIDE_AUTH_URL
export const FETCH_COUNTRIES = process.env.REACT_APP_FETCH_COUNTRIES
export const FETCH_STATES = process.env.REACT_APP_FETCH_STATES
export const FETCH_CITIES = process.env.REACT_APP_FETCH_CITIES

export const FETCH_SUBJECTS = process.env.REACT_APP_FETCH_SUBJECTS;
export const FILE_UPLOAD = process.env.REACT_APP_FILE_UPLOAD;
export const TEST_SERIES_DETAILS = process.env.REACT_APP_TEST_SERIES_DETAILS;