import { useState } from "react";
import axios from "axios";
import { STRIDE_AUTH_URL, FETCH_STATES } from "../utils/constants/constants";

const useFetchAllStates = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );

    const FetchStates = async ( country ) => {

        setIsLoading( true );

        try {
            const res = await axios.get( `${ STRIDE_AUTH_URL }${ FETCH_STATES }?country=${ country }` );
            setResponse( res.data );
            setError( null );
        } catch ( err ) {
            setError( err );
            console.log( err );
        } finally {
            setIsLoading( false );
        }
    };

    return { response, error, isLoading, FetchStates };
};

export default useFetchAllStates;