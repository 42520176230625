import React, { useState, useEffect } from "react";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics/index";
import { numberTypeCategory, numberTypeAction } from "../../utils/GoogleAnalytics/events/categories";
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import "../../styles.css";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useSelector } from 'react-redux';

const PhoneNumberInputStep = ( { question, answer, onAnswerChange, onNext, onBack, isPagination, isQuestionNavigation, isValidation } ) => {

    const [isValid, setIsValid] = useState( false );
    const [phoneNumber, setPhoneNumber] = useState( "" );
    const [showError, setShowError] = useState( false );
    const clientConfig = useSelector( state => state.clientConfiguration );

    //useEffect( () => {
    //    console.log( "Current maxLength: ", maxLength );
    //}, [maxLength] );

    useEffect( () => {
        if ( answer ) {
            setPhoneNumber( answer );
            if ( isValidPhoneNumber( answer ) ) {
                setIsValid( true );
                isValidation( true );
            } else {
                setIsValid( false );
                isValidation( false );
            }
        }
    }, [question, answer] );

    const handleInputChange = ( e ) => {
        const value = e || ""; 
        onAnswerChange( value );
        if ( isValidPhoneNumber( value ) ) {
            setIsValid( true );
            isValidation( true );
        } else {
            setIsValid( false );
            isValidation( false );
        }
        setPhoneNumber( value );
        TrackGoogleAnalyticsEvent( numberTypeCategory, numberTypeAction );

        if ( question.text.includes( "Please provide coordinator's contact number" ) ) {
            localStorage.setItem( 'contact_person_phone', value );
        }
    };

    const handleBlur = () => {
        if ( !isValidPhoneNumber( phoneNumber ) ) {
            setShowError( true );
        } else {
            setShowError( false );
        }
    };

    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    return (
        <div className={`flex flex-col justify-between ${isPagination ? "mb-4" : "mt-2 lg:h-full" }`}>
            <div className="flex flex-col">
                <QuestionAndDescription question={ question } />
                <div className="mt-2">
                    <PhoneInput
                        international
                        className="sm:w-full lg:w-1/2 rounded-md"
                        defaultCountry="IN"
                        value={ answer }
                        onChange={ ( e ) => handleInputChange( e ) }
                        onBlur={ handleBlur }
                        placeholder={ question?.placeholder_text }
                        countryCallingCodeEditable={ false }
                    />
                    {
                        showError &&
                        <p className="text-red-500 text-xs left-0 mt-2">
                            Invalid Phone Number. Please enter a Phone Number.
                        </p>
                    }
                </div>
            </div>
            {
                isPagination ?
                    <></>
                    :
                    isQuestionNavigation ?
                        <>
                            <SubmitButton onNext={ onNext } />
                        </>
                        :
                        <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                            {
                                question?.question_optional === "optional" && (
                                    <button className="skip-button" onClick={ onNext } >
                                        Skip
                                    </button>
                                )
                            }
                            {
                                perQuestionTimer ?
                                    <></> :
                                    <button className="back-button" onClick={ onBack } >
                                        Back
                                    </button>

                            }
                            <button
                                className="next-button"
                                onClick={ isValid ? onNext : null }
                                style={ {
                                    backgroundColor: isValid ? `${ clientConfig.primary_color }` : `${ clientConfig.primary_color }80`,  
                                    cursor: isValid ? 'pointer' : 'not-allowed'
                                } }
                            >
                                Next
                            </button>
                        </div>
            }
        </div>
    );
};

export default PhoneNumberInputStep;
